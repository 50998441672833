import React, { useEffect, useRef, useState } from 'react';
import CustomiseHeader from '../components/CustomiseHeader';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import Layout from '../../../components/Layout';
import BundleCustomiseSection from '../components/BundleCustomiseSection';
import { andAdd, andDecrease, andIncrease, bundleDisplayIdxComparer, calcBundleCost, findProduct, isBundleInStock, removeDisabledItem, removeOptions } from '../../../helpers/bundleHelpers';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { historyMW } from '../../../helpers/routingHelpers';
import { useAddBundleToBasketMutation, useUpdateBasketMutation } from '../../../services/basket.api';
import hash from 'object-hash';
import PopupBannerController from '../../../components/PopupBannerController';
import { config } from '../../../config';

const dietIcon = [];

export const getBundle = (bundles, name) => {
  let bundle;
  let bundleSections;
  if (bundles) {
    bundle = bundles.find(el => el.name === name);
    if (bundle) {
      bundleSections = [...bundle?.sections];
      bundleSections.sort(bundleDisplayIdxComparer);
      return { ...bundle, sections: bundleSections };
    }
  }
};

export const getCustomBundle = (bundle, basketItems, customID, removeOptions) => {
  const customBundle = basketItems.find(el => el.customID === customID);
  if (customID && customBundle) {
    return customBundle;
  } else {
    return removeOptions(bundle);
  }
};

const Bundle = ({ restaurantId, refreshBasket }) => {
  const basketBundles = useSelector(state => state.basket.bundles);
  const menuBundles = useSelector(state => state.menu.bundles);
  const outOfStockItems = useSelector(state => state.menu.outOfStockItems);
  const { isDelivery } = useSelector(state => state.basket);
  const { inApp } = useSelector(state => state.session);
  const location = useLocation();
  const history = useHistory();
  const { itemId: name } = useParams();
  const { width: screenWidth } = useWindowDimensions();
  const customID = location.state?.customID;
  const bundle = getBundle(menuBundles, name);
  const customBundle = getCustomBundle(bundle, basketBundles, customID, removeOptions);
  const [customisedItem, setCustomisedItem] = useState(customBundle);
  const [quantity, setQuantity] = useState(customBundle?.quantity || 1);
  const [invalidSections, setInvalidSections] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const [disabledItems, setDisabledItems] = useState([]);
  const [addBundleToBasket] = useAddBundleToBasketMutation();
  const [updateBasket] = useUpdateBasketMutation();
  const { items, numberOfItems } = useSelector(state => state.basket);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(0);
  const pageRef = useRef(null);

  const handleSelect = (product, customisedBundle, sectionId, maxItems) => {
    setCustomisedItem(findProduct(andAdd, product, customisedBundle, sectionId, maxItems));
  };

  const handleIncrease = (product, customisedBundle, sectionId, maxItems) => {
    setCustomisedItem(findProduct(andIncrease, product, customisedBundle, sectionId, maxItems));
  };

  const handleDecrease = (product, customisedBundle, sectionId) => {
    setCustomisedItem(findProduct(andDecrease, product, customisedBundle, sectionId));
  };

  if (bundle && !isBundleInStock(bundle, outOfStockItems)) {
    history.goBack();
  }

  useEffect(() => {
    if (customisedItem) {
      setCustomisedItem(removeDisabledItem(disabledItems, customisedItem));
    }
  }, [disabledItems]);

  const addItemToBasket = async () => {
    const sections = Array.from(document.getElementsByClassName('customise-section'));
    const firstInvalidSectionNode = sections.filter(el => invalidSections.find(s => el.dataset.sectionErrorId === s))[0];
    if (invalidSections.length && firstInvalidSectionNode) {
      window.scrollTo({ top: window.pageYOffset + firstInvalidSectionNode.getBoundingClientRect().top - (window.innerHeight / 2) + 200, behavior: 'smooth', block: 'center' });
      setShowErrors(true);
    } else if (customID) {
      await updateBasket({
        restaurantId,
        numberOfItems,
        items,
        bundles: basketBundles.map(bundle => bundle.customID === customID ? { ...customisedItem, quantity, customID: hash(customisedItem) } : bundle)
      });
      refreshBasket();
      historyMW.push('/basket', isDelivery, history);
    } else {
      const response = await addBundleToBasket({ ...customisedItem, calculatedCost: currentPrice * quantity, quantity, customID: hash(customisedItem), restaurantId });
      if (response.data.status === 'ERROR') {
        setIsOpen(true);
      } else {
        refreshBasket();
        historyMW.push(`/menu/${restaurantId}`, isDelivery, history);
      }
    }
  };

  useEffect(() => {
    if (customisedItem) {
      setCurrentPrice(calcBundleCost(customisedItem));
    }
  }, [customisedItem]);

  return (
    <Layout
      backgroundColour='#3AAA20'
      title='Customise your meal deal'
      backButton={() => history.goBack()}
      heroPaddingSize='is-xxsmall extra-vertical-padding'
      noImage
      isCustomise
    >
      <div className='customise-container' ref={pageRef}>
        {
          bundle &&
          <>
            <CustomiseHeader
              isBundle
              name={bundle?.name}
              description=''
              price={bundle?.basePrice}
              customisedItem={customisedItem}
              setShowErrors={setShowErrors}
              invalidSections={invalidSections}
              dieAdtIcon={dietIcon}
              quantity={quantity}
              setQuantity={setQuantity}
              restaurantId={restaurantId}
              screenWidth={screenWidth}
              edit={!!customID}
              setDisabledItems={setDisabledItems}
              refreshBasket={refreshBasket}
              addToBasket={addItemToBasket}
              isOpen={isOpen}
              inApp={inApp}
              currentPrice={currentPrice}

            />

            {
              bundle?.sections.sort(bundleDisplayIdxComparer).map((el, idx) => (
                <BundleCustomiseSection
                  bundleSection={el}
                  customisedBundle={customisedItem}
                  key={`${idx}`}
                  handleSelect={handleSelect}
                  handleIncrease={handleIncrease}
                  handleDecrease={handleDecrease}
                  invalidSections={invalidSections}
                  setInvalidSections={setInvalidSections}
                  showErrors={showErrors}
                  compare={bundleDisplayIdxComparer}
                  disabledItems={disabledItems}
                  outOfStockItems={outOfStockItems}
                />
              ))
            }

            <CustomiseHeader
              isBundle
              name={bundle?.name}
              description=''
              price={bundle?.basePrice}
              customisedItem={customisedItem}
              setShowErrors={setShowErrors}
              invalidSections={invalidSections}
              dietIcon={dietIcon}
              quantity={quantity}
              setQuantity={setQuantity}
              restaurantId={restaurantId}
              screenWidth={screenWidth}
              edit={!!customID}
              setDisabledItems={setDisabledItems}
              refreshBasket={refreshBasket}
              addToBasket={addItemToBasket}
              isOpen={isOpen}
              inApp={inApp}
              currentPrice={currentPrice}
            />
          </>
        }
      </div>
      <PopupBannerController justifyEnd parentRef={pageRef}>
        <button className='checkout-btn btn btn-primary' style={{width: 'min-content'}} onClick={addItemToBasket}>
          Add to basket ({quantity}) - {config.BRAND === 'PE' ? '£' : '€'}{(currentPrice * quantity).toFixed(2)}
        </button>
      </PopupBannerController>
    </Layout>
  );
};

export default Bundle;
