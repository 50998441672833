import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../../components/Layout';
import SearchInput from '../components/SearchInput';
import { postcodeRegex } from '../HomeController';
import LoaderBlack from '../../../img/common/Loader_360x360.gif';
import uberLogo from '../../../img/Delivered_Horizontal_blk.svg';
import { gtmEvent } from '../../../helpers/commonHelpers';
import imageRightBottomMobile from '../../../img/hero/hero-right-bottom-mobile.png';
import imageRightBottomDesktop from '../../../img/hero/hero-right-bottom-desktop.png';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const OrderOnline = (
  {
    searchProps: {
      isDelivery,
      searchTerm,
      searchResults,
      showSearchError,
      searchByText,
      updateSearchTerm,
      isLoading,
      setHasUserTyped
    },
    banners,
    backgrounds,
  }
) => {
  const { inApp } = useSelector(state => state.session);
  const { width, isSmallScreen } = useWindowDimensions();
  const { isSmallScreen: isXsmallScreen } = useWindowDimensions(555);
  useEffect(() => {
    gtmEvent('OOP_Joint_Homepage', { channel: inApp ? 'App' : 'Web' });
    if (searchTerm && searchTerm.length > 0 && !postcodeRegex.test(searchTerm.trim()))
      updateSearchTerm('');
  }, []);
  
  const LoadByText = (isDelivery) => {
    searchByText(isDelivery);
    setHasUserTyped(false);
  };

  const buttons = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  };
  const backgroundImage = isXsmallScreen ? backgrounds?.Mobile : backgrounds?.Desktop;
  return (
    <Layout
      title='Order Online'
      subtitle='From Collection to Delivery, order those cravings away!'
      heroPaddingSize={''}
      collapsePadding
      sectionSize='is-small'
      banners={banners}
      heroBgImage={
        <div style={{
          position: 'absolute',
          width: isSmallScreen ? '100%' : width >= 1440 ? width : width + ((1440-width) / 2), 
          height: '100%', 
          backgroundImage: `url(${isSmallScreen ? imageRightBottomMobile: imageRightBottomDesktop})`, backgroundSize: 'contain', backgroundPositionX: 'right',
          backgroundRepeat: 'no-repeat' }} />
      }
      bgImage={isSmallScreen ? backgrounds?.Mobile : backgrounds?.Desktop}
      isFluid
    >
      <div className="column is-8 is-offset-2 search-container-container">
        <div className={`block pt-5 searchContainer ${backgroundImage ? 'has-image' : ''}`}>
          <div className="columns is-flex-wrap-wrap">
            <div className="column is-12 is-flex is-justify-content-center" style={{paddingLeft: '1.5rem', paddingRight: '1.5rem'}}>
              <div className="content" style={{width: '640px' }}>
                <SearchInput
                  label='Enter your postcode or location'
                  errorMessage={searchResults?.error ? searchResults.error : 'Please enter a valid postcode'}
                  valueCallback={updateSearchTerm}
                  value={searchTerm}
                  onLocationClick={() => {}}
                  isError={showSearchError}
                  placeholder='Search location'
                  id='searchInput'
                  hideLocationIcon={true}
                  showIcon={false}
                />
              </div>
            </div>

            <div className='p-3 mt-5 mt-1-mobile mb-4' style={buttons}>
              <button className='btn btn-primary w-190' onClick={() => LoadByText(false)}>Order for Collection</button>
              <button className='btn btn-primary w-190' onClick={() => LoadByText(true)}>Order for Delivery</button>
            </div>
            {isLoading &&
              <div style={{width: '100%', height: '200px', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                <img src={LoaderBlack} style={{ width: 64, height: 64 }} />
              </div>
            }

            {isDelivery && (<div className="mt-6 w-100" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',  marginBottom: '3rem' }}>
              <img role="uber-logo" className="mt-2" style={{ 'height': '1.75rem' }} src={uberLogo} alt='logo' />
            </div>)}

          </div>
        </div>
        {/* The following is required to preload the spinner. */}
        <img src={LoaderBlack} style={{ width: 0, height: 0 }} />
      </div>
    </Layout>
  );
};

export default OrderOnline;
