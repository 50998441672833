import React from 'react';
import { useSelector } from 'react-redux';
import filterIcon from '../img/menu/filter-btn.svg';
import ellipse from '../img/icons/Ellipse.svg';
import { gtmEvent } from '../helpers/commonHelpers';

const FilterMenuButton = ({ setDietModalIsOpen }) => {
  const { isDelivery } = useSelector(state => state.basket);
  const { inApp } = useSelector(state => state.session);
  const {filters} = useSelector(state => state.menu)
  return (
    <button
      className={'btn-filtering p-0 is-absolute'}
      onClick={() => {
        gtmEvent(isDelivery ? 'Delivery_Open_Dietary_Filter' : 'Collection_Open_Dietary_Filter', { channel: inApp ? 'App' : 'Web' });
        setDietModalIsOpen(true);
      }}      
    >
      <i className="icon is-large">
        <img src={filterIcon} alt="dietary preference button" />
      </i>
      {
        filters?.length ?
          <div style={{ position: 'absolute', height: '100%', width: '100%', bottom: '0', right: '0' }}>
            <i className="icon is-medium" style={{ position: 'absolute', bottom: '-7px', right: '-7px' }}>
              <img src={ellipse} alt="dietary preference button" />
              <p  style={{ position: 'absolute', color: 'white', fontStyle: 'normal', fontSize: '0.8rem' }} >{filters.length}</p>
            </i>
          </div>
          :
          null
      }

    </button>
  );
};

export default FilterMenuButton;
