import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../components/Layout';
import locationIcon from '../../../img/icons/locationPin.svg';
import googlePlay from '../../../img/common/PlayStoreLogo.png';
import appStore from '../../../img/common/AppStoreLogo.png';
import { freshRelevance, getDistanceText, gtmEvent } from '../../../helpers/commonHelpers';
import { config } from '../../../config.js';
import { format } from 'date-fns';
import { useSendToHome } from '../../../hooks/useSendToHome';
import { clearBasketLocal } from '../../../store/basketSlice';
import half from '../../../img/loyalty/stamps/half.svg';
import full from '../../../img/loyalty/stamps/full.svg';
import info from '../../../img/icons/iconInfo.svg'
import onboarding from '../../../img/icons/onboarding.svg';
import { useLazyFindRestaurantByIdQuery } from '../../../services/restaurant.api';
import LiveChatBubble from '../../../img/icons/bot-avatar-small.svg';
import { setSessionSearchTerm } from '../../../store/sessionSlice';

const brand = config.BRAND;

export const formatCollectionTime = (collectionTime) => {
  if (!collectionTime) return '';
  return format(Date.parse(collectionTime), 'HH:mm');
};

export const getLoyaltyConfirmationText = (slicesEarnt, bounceBackBravoCopy) => {
  let text = '';
  switch (slicesEarnt) {
    case 6:
      text = 'Bravo! You’ve earned 1/2 a pizza stamp by placing your order today.';
      break;
    case 12:
      text = 'Bravo! You’ve earned 2 x 1/2 pizza stamps by placing your order today.';
      break;
    case 18:
      text = 'Bravo! You’ve earned 3 x 1/2 pizza stamps by placing your order today.';
      break;
  }
  if (bounceBackBravoCopy) {
    text += ` ${bounceBackBravoCopy}`;
  }
  return text;

};

const getLoyaltyImage = (slicesEarnt) => {
  switch (slicesEarnt) {
    case 6:
      return half;
    case 12:
      return full;
    default:
      return full;
  }
};

const formatDeliveryTime = (time) => {
  const [hours, minutes] = time.split(':');
  return `${hours}:${minutes}`;
};

const OrderSummary = ({ onChangeRestaurant, loyalty, order, isRouted, getOrder }) => {
  const { iorderStatus, customerFacingId, id, slicesEarnt, delivery, status, webviewUrl, bounceBackBravoCopy, restaurantDistance, customer, total } = order;
  const { restaurant, inApp, features } = useSelector(state => state.session);
  const sendToHome = useSendToHome(!!order.delivery);
  const [restaurantDetails, setRestaurantDetails] = useState(null);
  const cancelled = ['REJECTED', 'FAILED', 'CANCELLED', 'VOID'].includes(order?.status.toUpperCase());
  const dispatch = useDispatch();

  const handleSendToHome = (e) => {
    e.stopPropagation();
    if (inApp) {
      const data = {
        type: 'CLOSE_OOP_AND_CLEAR_BASKET',
      };
      window.ReactNativeWebView?.postMessage(JSON.stringify(data));
    } else {
      dispatch(setSessionSearchTerm(''));
      sendToHome();
    }
  };

  const [getRestaurant] = useLazyFindRestaurantByIdQuery();
  const googlePlayClicked = () => {
    freshRelevance('pageChange', null, { 'sendBeacon': true });
    window.open('https://play.google.com/store/apps/details?id=com.pizzaexpress.appv2', '_blank');
  };
  const interval = useRef(null);

  const appStoreCLicked = () => {
    freshRelevance('pageChange', null, { 'sendBeacon': true });
    window.open('https://apps.apple.com/gb/app/pizzaexpress/id440565075', '_blank');
  };

  useEffect(() => {
    if (total > 0 && isRouted) {
      gtmEvent(delivery ? 'delivery_confirmation_review' : 'collection_confirmation_review', { order_value: total, channel: inApp ? 'App' : 'Web' });
    } else if(total > 0 && !isRouted) {
      gtmEvent(delivery ? 'delivery_confirmation' : 'collection_confirmation', { order_value: total, channel: inApp ? 'App' : 'Web' });
    }
    dispatch(clearBasketLocal());
  }, [delivery, total, isRouted]);

  const fetchRestaurantDetails = async (restaurantId) => {
    try {
      const { data } = await getRestaurant(restaurantId);
      setRestaurantDetails(data);
    } catch (error) {
      console.error('Error fetching restaurant details:', error);
    }
  };

  useEffect(() => {
    const updateRestaurantDetails = async () => {
      if (isRouted) {
        await fetchRestaurantDetails(order.restaurantId);
      } else if (order && order.restaurantId) {
        setRestaurantDetails(restaurant);
      }
    };

    updateRestaurantDetails();

    if (delivery) {
      interval.current = setInterval(async () => {
        await getOrder({ id: order.id, restaurantId: order.restaurantId });
      }, 60000);
    } else {
      getOrder({ id: order.id, restaurantId: order.restaurantId });
    }

    return () => clearInterval(interval.current);
  }, [restaurant, delivery]);

  return (
    <Layout
      hideHero
      ctaFunction={onChangeRestaurant}
      sectionSize={'is-order-confirmation'}
    >
      <div style={{ width: 'calc(400px + 48px)', maxWidth: '100%', margin: 'auto', textAlign: 'center', padding: '0 24px', marginTop: '-10px' }}>
        {
          customer && customer.email && customerFacingId &&
          <img src={features?.enableStagingFreshRelevance ? `https://d1f0tbk1v3e25u.cloudfront.net/pc/atkp7h2h/?e=${customer.email}&r=${customerFacingId}` : `https://d1f0tbk1v3e25u.cloudfront.net/pc/uq1see9h/?e=${customer.email}&r=${customerFacingId}`} id="__tms_pc" height="1" width="1"/>
        }
        <h2 className={`title smaller-title-confirmation overflow-centered ${inApp ? 'in-app' : ''}`} style={{ marginTop: '1rem', lineHeight: 1 }}>ORDER <span style={{ whiteSpace: 'nowrap' }}>{`#${customerFacingId}`}</span></h2>
        {
          inApp && cancelled ?
            <div style={{ padding: '16px 24px', backgroundColor: '#ffe39a', alignItems: 'flex-start', margin: '16px 0', width: '100%' }}>
              <p style={{ color: '#796429', fontSize: '14px', alignItems: 'center', display: 'flex' }}><img src={info} alt="Info" style={{ height: '16px', width: '16px', marginRight: '12px' }} /> Your order has been cancelled.</p>
            </div>
            : null
        }
        {
          delivery ?
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {!inApp &&
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <p className='next-steps-header'>Next Steps:</p>
                  <ol style={{ textAlign: 'left', margin: '8px 0', paddingLeft: '30px', }} className='next-steps-list' role='delivery-instructions'>
                    <li>Check your email</li>
                    <li>Your email has a verification code</li>
                    <li>Your email has a delivery tracking link</li>
                    <li>Give your code to the delivery driver</li>
                  </ol>
                </div>
              }
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontWeight: 600 }}>
                <span role='delivery-pin'>Code {delivery.deliveryPin}</span>
                <p style={{ fontWeight: 400, fontSize: `${inApp ? '14px' : '16px'}` }}>You must give this code to your delivery rider.</p>
              </div>
            </div>
            :
            <>
              {status === 'PENDING' ?
                <p className='order-confirmation-thank-you'>Thank you for your order at our {restaurantDetails?.name} Pizzeria. You will receive an email with your order details shortly. We will send you another email closer to your collection time to let you know your order is being prepared.</p> :
                <p className='order-confirmation-thank-you'>Thank you for your order at our {restaurantDetails?.name} Pizzeria. You will receive an email with your order details shortly.</p>
              }
            </>
        }
        <div style={{ width: 500, maxWidth: '100%', margin: 'auto', paddingTop: 0 }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='order-summary-divider' />
            {
              delivery ?
                <div >
                  <div className="block has-text-centered" style={{ marginBottom: '0rem' }}>
                    <h4 id="deliveryTime" className={`title smaller-title-confirmation overflow-centered ${inApp ? 'in-app' : ''}`} style={{ lineHeight: '0.9' }}>ESTIMATED <p>DELIVERY TIME</p></h4>
                    <p className={`order-confirmation-time ${inApp ? 'in-app' : ''}`} role='delivery-time'>{formatDeliveryTime(delivery.minDeliverTime)} - {formatDeliveryTime(delivery.maxDeliverTime)}</p>
                  </div>
                  <div className="block" style={{ margin: '24px 0 16px 0' }}>
                    <p className='block delivering-to'>Delivering to:</p>
                    <div className="level is-mobile is-justify-content-center">
                      <div className="level-item is-narrow is-shrinkable">
                        <i className="icon is-medium">
                          <img src={locationIcon} />
                        </i>
                      </div>

                      <div className="level-item is-justify-content-flex-start is-narrow is-shrinkable" role='delivery-address' style={{ fontWeight: 600 }}>
                        <p className='delivering-to-address'>{delivery.deliveryAddress.addressLine1}, {delivery?.userPostcode}</p>
                      </div>
                    </div>
                  </div>
                </div>
                :
                <>
                  <div className="block has-text-centered" style={{ marginBottom: '0rem' }}>
                    <h2 id="collectionTime" role='collection-time' className="title smaller-title-confirmation overflow-centered" style={{ lineHeight: '0.9' }}>COLLECTION TIME</h2>
                    <p className='order-confirmation-time'>
                      {formatCollectionTime(iorderStatus?.collectionTime)}
                    </p>
                  </div>
                  <div className="level is-mobile" style={{ alignItems: 'start' }}>
                    <div className="level-item is-narrow is-shrinkable">
                      <img src={locationIcon} style={{ width: 24, paddingTop: 2 }} />
                    </div>
                    <div className="level-item is-justify-content-flex-start is-shrinkable">
                      <div className="block restaurant-info" style={{ textAlign: 'left' }}>
                        <p className='restaurant-name'>{restaurantDetails?.name}</p>
                        <p>{getDistanceText(restaurantDetails?.distance)}</p>
                        <a href={`http://maps.apple.com/?q=${restaurantDetails?.fullAddress?.split(' ').join('+')}`} target='_blank' rel="noreferrer">
                          {restaurantDetails?.fullAddress}
                        </a>
                        <a href={`tel:${restaurantDetails?.telephone}`} target='_blank' rel="noreferrer">
                          {restaurantDetails?.telephone}
                        </a>
                      </div>
                    </div>
                  </div>
                </>
            }
            <>
              <div className="block" style={{ marginTop: 0, marginBottom: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 16 }}>
                {
                  delivery &&
                  <button disabled={!webviewUrl || cancelled} className='btn btn-primary' onClick={() => webviewUrl ? window.open(webviewUrl, '_blank') : null}>
                    Track my order
                  </button>
                }
                <button
                  onClick={handleSendToHome}
                  className='btn btn-primary'
                  data-testid={'doneBtn'}
                >
                  Done
                </button>
              </div>
              {inApp && <div className='order-summary-divider' />}
            </>
            {inApp && delivery ?
              <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <img style={{ display: 'inline', height: '1.5rem', paddingRight: '0.5rem' }} src={LiveChatBubble}></img><a className='dm-sans' style={{ cursor: 'pointer', fontWeight: '400' }} onClick={() => window.open('https://www.pizzaexpress.com/help-and-contact', '_blank')}>Delivery help</a>
                </div>
              </>
              : <></>
            }
            {!inApp && brand === 'PE' ?
              <>
                <div className='order-summary-divider' />
                <div className="block">
                  <img src={loyalty && slicesEarnt > 0 ?
                    getLoyaltyImage(slicesEarnt) :
                    onboarding} style={{ maxHeight: '200px' }} />
                  {loyalty && slicesEarnt > 0
                    ? <p className='pt-4 pb-4' style={{ fontWeight: 600 }}>{getLoyaltyConfirmationText(slicesEarnt, bounceBackBravoCopy)}</p>
                    : <><p style={{ fontWeight: 600 }} className='pt-4'>Do you have pizza on your mind 24/7 too?</p><p className=' pb-4' > Us too. Order from our app to get exclusive loyalty benefits. Download our app to get started.</p></>}

                  <div style={{ display: 'flex', paddingBottom: '0.5rem' }}>
                    <div style={{ width: '50%', textAlign: 'end', marginRight: 8, }}>
                      <img id="googlePlay" role='google-play' src={googlePlay} height={120} style={{ cursor: 'pointer', backgroundColor: 'black', borderRadius: 5, width: 120 }} onClick={googlePlayClicked} />
                    </div>
                    <div style={{ width: '50%', textAlign: 'start', marginLeft: 8 }}>
                      <img id="appStore" role='app-store' src={appStore} height={120} style={{ cursor: 'pointer', backgroundColor: 'black', borderRadius: 5, width: 120 }} onClick={appStoreCLicked} />
                    </div>
                  </div>
                </div>
              </>
              : <div></div>
            }
          </div>
        </div>
      </div>
    </Layout >
  );
};

export default OrderSummary;
