import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import braintree from 'braintree-web';
import errorIcon from '../../../img/icons/error.svg';
import closeError from '../../../img/icons/closeError.svg';
import GreenTickAndText from '../../../components/shared/GreenTickAndText';
import { freshRelevance, gtmEvent } from '../../../helpers/commonHelpers';
import { config } from '../../../config';
import { paymentTypes } from '../../../contants/paymentType';

const PayByCard = ({
  clientToken,
  submitPaymentCancel,
  submitPaymentFail,
  paymentSuccessful,
  generatePaymentBody,
  grandTotal,
  storeCardInVault,
  setStoreCardInVault,
  optOut,
  setOptOut,
  currentlySubmittingPayment,
  setCurrentlySubmittingPayment,
  pay,
  customer,
  optIn,
  setDisablePaymentBackButton,
  inApp
}) => {
  const [hostedFieldsInstance, setHostedFieldsInstance] = useState(null);
  const [fields, setFields] = useState({});
  const [formValid, setFormValid] = useState(false);
  const [displayInvalidFields, setDisplayInvalidFields] = useState({
    email: false,
    creditCard: false,
    cvv: false,
    expiry: false,
  });
  const { cmsConfig } = useSelector(state => state.session);  
  
  useEffect(() => {
    const renderDropIn = async () => {
      if (hostedFieldsInstance !== null) return;

      try {
        const clientInstance = await braintree.client.create({ authorization: clientToken });
        var options = {
          client: clientInstance,
          styles: {
            input: {
              'font-family': 'dm-sans, sans-serif',
              'font-size': '16px',
              'line-height': '16px',
              color: 'rgb(8, 16, 32)',
              padding: 'auto 0.625rem',
              'background-color': '#ffffff',
              'max-width': '500px',
              'border-radius': '50px',
            },
            'input#credit-card-number': {},
            'input#cvv': {},
            'input#expiration': {},
            'input#credit-card-number::-ms-input-placeholder': { color: '#C6C5C5' },
            'input#credit-card-number:-ms-input-placeholder': { color: '#C6C5C5' },
            'input#credit-card-number::-moz-placeholder': { color: '#C6C5C5' },
            'input#credit-card-number:-moz-placeholder': { color: '#C6C5C5' },
            'input#credit-card-number::-webkit-input-placeholder': { color: '#C6C5C5' },
            'input#credit-card-number:placeholder-shown': { color: '#C6C5C5' },
            'input#credit-card-number::placeholder': { color: '#C6C5C5' },
            'input#cvv::-ms-input-placeholder': { color: '#C6C5C5' },
            'input#cvv:-ms-input-placeholder': { color: '#C6C5C5' },
            'input#cvv::-moz-placeholder': { color: '#C6C5C5' },
            'input#cvv:-moz-placeholder': { color: '#C6C5C5' },
            'input#cvv::-webkit-input-placeholder': { color: '#C6C5C5' },
            'input#cvv:placeholder-shown': { color: '#C6C5C5' },
            'input#cvv::placeholder': { color: '#C6C5C5' },
            'input#expiration::-ms-input-placeholder': { color: '#C6C5C5' },
            'input#expiration:-ms-input-placeholder': { color: '#C6C5C5' },
            'input#expiration::-moz-placeholder': { color: '#C6C5C5' },
            'input#expiration:-moz-placeholder': { color: '#C6C5C5' },
            'input#expiration::-webkit-input-placeholder': { color: '#C6C5C5' },
            'input#expiration:placeholder-shown': { color: '#C6C5C5' },
            'input#expiration::placeholder': { color: '#C6C5C5' }
          },
          fields: {
            number: { container: '#card-number', placeholder: 'Enter your 16-digit card number' },
            cvv: { container: '#cvv', placeholder: 'Last 3-digits' },
            expirationDate: { container: '#expiration-date', placeholder: 'MM/YY' }
          }
        };
        const hostedFieldsInstance = await braintree.hostedFields.create(options);
        setFields(hostedFieldsInstance.getState());

        hostedFieldsInstance.on('inputSubmitRequest', event => {
          submitPayment(event);
        });
        hostedFieldsInstance.on('blur', event => {
          checkFormValidity(event);
        });

        setHostedFieldsInstance(hostedFieldsInstance);
      } catch (err) {
        console.error('Error.', err);
      }
    };

    renderDropIn();

    return () => submitPaymentCancel();
  }, []);

  const postPayment = async (payload, hostedFieldsInstance) => {
    const response = await pay(generatePaymentBody(payload, paymentTypes.Card));
    const data = response.data || response.error?.data;
    if (data?.status !== 'OK') {
      submitPaymentFail(data);
      setCurrentlySubmittingPayment(false);
      return;
    }
    freshRelevance('pageChange', null, { 'sendBeacon': true });
    hostedFieldsInstance.teardown((teardownErr) => {
      if (teardownErr) console.error('Could not tear down the Hosted Fields form!');
    });
    paymentSuccessful(data);

    /*
    if (data.vaultId) {
      storeVaultId(data.vaultId);
    }*/
  };

  const checkFormValidity = (event) => {
    var formValid = Object.keys(event.fields).every(key => {
      return event.fields[key].isValid;
    });

    let newDisplayInvalidFields = displayInvalidFields;
    newDisplayInvalidFields.creditCard = !event.fields.number.isValid && !event.fields.number.isEmpty ? true : false;
    newDisplayInvalidFields.cvv = !event.fields.cvv.isValid && !event.fields.cvv.isEmpty ? true : false;
    newDisplayInvalidFields.expiry = !event.fields.expirationDate.isValid && !event.fields.expirationDate.isEmpty ? true : false;

    setFields(event.fields);
    setDisplayInvalidFields(newDisplayInvalidFields);
    setFormValid(formValid);
  };

  const submitPayment = () => {
    gtmEvent('cardpay_click', { channel: inApp ? 'App' : 'Web' });
    var event = hostedFieldsInstance.getState();
    setFields(event.fields);
    if (!currentlySubmittingPayment) {

      if (formValid === true) {
        setCurrentlySubmittingPayment(true);
        setDisablePaymentBackButton(true);
        hostedFieldsInstance.tokenize({}, async (err, payload) => {
          if (err) {
            console.error(err);
            return;
          }
          
          var threeDSecureParameters = {
            amount: grandTotal,
            nonce: payload.nonce,
            bin: payload.details.bin,
            email: customer?.emailAddress,
            challengeRequested: cmsConfig?.enable3DSChallengeRequested ?? true,
            onLookupComplete: (data, next) => {
              // use `data` here, then call `next()`
              next();
            }
          };

          try {
            let threeDSecure;

            const clientInstance = await braintree.client.create({ authorization: clientToken });

            threeDSecure = await braintree.threeDSecure.create({ version: '2', client: clientInstance });

            const payByCardContainer = document.getElementById('pay-by-card-container');

            threeDSecure.on('authentication-iframe-available', (event, next) => {
              payByCardContainer.appendChild(event.element);
              event.element.className = 'three-d-secure-container';
              next();
            });

            try {
              let response = await threeDSecure.verifyCard(threeDSecureParameters);
              threeDSecure.teardown();
              var threeDSecureElem = document.getElementsByClassName('three-d-secure-container')[0];
              if (threeDSecureElem) {
                payByCardContainer.removeChild(threeDSecureElem);
              }
              postPayment(response, hostedFieldsInstance);
            } catch (error) {
              console.error(error);
              threeDSecure.teardown();
              var threeDSecureElem2 = document.getElementsByClassName('three-d-secure-container')[0];
              if (threeDSecureElem2) {
                payByCardContainer.removeChild(threeDSecureElem2);
              }
              submitPaymentFail();
            }
          } catch (err) {
            console.error(err);
            submitPaymentFail();
            setCurrentlySubmittingPayment(false);
          }
        });
      } else {
        const newDisplayInvalidFields = { ...displayInvalidFields };
        if (
          event.fields.number.isValid === false ||
          event.fields.number.isEmpty === true
        )
          newDisplayInvalidFields.creditCard = true;
        if (
          event.fields.cvv.isValid === false ||
          event.fields.cvv.isEmpty === true
        )
          newDisplayInvalidFields.cvv = true;
        if (
          event.fields.expirationDate.isValid === false ||
          event.fields.expirationDate.isEmpty === true
        )
          newDisplayInvalidFields.expiry = true;

        setFields(event.fields);
        setDisplayInvalidFields(newDisplayInvalidFields);
      }
    }
  };

  const errorCross = () => {
    return (
      <div className="icon is-medium is-absolute" style={{ top: 'calc(57px / 2)', right: '1rem', zIndex: 20, transform: 'translateY(-50%)' }}>
        <img src={closeError} alt="closeError" />
      </div>
    );
  };

  const renderError = (text, name, marginTop) => {
    return (
      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'center', gap: 8, marginLeft: 10, marginTop }}>
        <img id={name + 'InnerInputError'} src={errorIcon} alt="error" />
        <small className='error-message'>{text}</small>
      </div>
    );
  };

  return (
    <div id="pay-by-card-container" className="has-text-left">
      <div id="pay-form">

        <div className='paybycardcontainer'>

          <div className="paybycardsection">
            <label className="label" style={{ marginBottom: 8 }} htmlFor="card-number">Card number</label>
            <div className="field is-relative" style={{ width: '100%' }}>
              <div
                style={displayInvalidFields.creditCard === true && !!fields.number && fields.number.isValid === false && fields.number.isEmpty === false ?
                  { backgroundColor: 'rgba(232,30,44,0.2)', height: 57, border: '1px solid rgb(232,30,44)', borderRadius: 50, padding: '16px 24px', fontSize: 16 } :
                  { backgroundColor: '#ffffff', height: 57, borderRadius: 50, padding: '16px 24px', fontSize: 16 }}
                id="card-number"
                name="cardnumber"
                autoComplete="on" />
              {displayInvalidFields.creditCard === true && !!fields.number && fields.number.isValid === false && fields.number.isEmpty === false && (
                errorCross()
              )}
            </div>

            {displayInvalidFields.creditCard === true && !!fields.number && fields.number.isValid === false && fields.number.isEmpty === false && (
              <div>
                {renderError('Please enter a valid card number', 'card')}
              </div>
            )}
          </div>
          <div className="paybycardsection row">
            <div className='paybycard-input-container' >
              <label className="label" style={{ marginBottom: 8 }} htmlFor="expiration-date">Expiration date</label>
              <div className="field is-relative">
                <div
                  style={displayInvalidFields.expiry === true && !!fields.expirationDate && fields.expirationDate.isValid === false && fields.expirationDate.isEmpty === false ?
                    { backgroundColor: 'rgba(232,30,44,0.2)', height: 57, border: '1px solid rgb(232,30,44)', borderRadius: 50, padding: '16px 24px', fontSize: 16 } :
                    { backgroundColor: '#ffffff', height: 57, borderRadius: 50, padding: '16px 24px', fontSize: 16 }}
                  id="expiration-date"
                  name="expirationdate"
                  autoComplete="on" />
                {displayInvalidFields.expiry === true && !!fields.expirationDate && fields.expirationDate.isValid === false && fields.expirationDate.isEmpty === false && (
                  errorCross()
                )}
              </div>
              {displayInvalidFields.expiry === true && !!fields.expirationDate && fields.expirationDate.isValid === false && fields.expirationDate.isEmpty === false &&
                renderError('Invalid expiry date', 'date', -12)}
            </div>
            <div className='paybycard-input-container' >
              <label className="label" style={{ marginBottom: 8 }} htmlFor="cvv">CVV</label>
              <div className="field is-relative">
                <div
                  style={displayInvalidFields.cvv === true && !!fields.cvv && fields.cvv.isValid === false && fields.cvv.isEmpty === false ?
                    { backgroundColor: 'rgba(232,30,44,0.2)', height: 57, border: '1px solid rgb(232,30,44)', borderRadius: 50, padding: '16px 24px', fontSize: 16 } :
                    { backgroundColor: '#ffffff', height: 57, borderRadius: 50, padding: '16px 24px', fontSize: 16 }}
                  id="cvv"
                  name="cvv"
                  autoComplete="on" />
                {displayInvalidFields.cvv === true && !!fields.cvv && fields.cvv.isValid === false && fields.cvv.isEmpty === false && (
                  errorCross()
                )}
                {displayInvalidFields.cvv === true && !!fields.cvv && fields.cvv.isEmpty === true && (
                  errorCross()
                )}
              </div>

              {displayInvalidFields.cvv === true && !!fields.cvv && fields.cvv.isValid === false && fields.cvv.isEmpty === false &&
                renderError('Invalid CVV number', 'cvv', -12)}
            </div>

          </div>
          {customer &&
            <GreenTickAndText
              selected={storeCardInVault}
              setSelected={setStoreCardInVault}
              id='storeCardInVaultButton'
              disabled={currentlySubmittingPayment}
              noMargin
            >
              Save these card details for future payments. You can manage your cards in My Profile.
            </GreenTickAndText>}
          {(customer?.emailOptIn === false || !optIn) && <GreenTickAndText
            selected={optOut}
            setSelected={setOptOut}
            id='detailOutButton'
            disabled={currentlySubmittingPayment}
            noMargin
          >
            When you make your payment, PizzaExpress will send you news and fantastic offers via email. Please tick the box if you do not want to receive this.
          </GreenTickAndText>}
          <div style={{ textAlign: 'center', margin: '' }}>
            <p className='tcs'>
              By clicking pay, you agree to our Click and Collect
              {' '}
              <span
                style={{ textDecoration: 'underline', fontWeight: 600, cursor: 'pointer', display: 'inline-block' }}
                onClick={() => window.open(config.BRAND === 'PE' ? 'https://www.pizzaexpress.com/terms-and-conditions/online-ordering' : 'https://www.milano.ie/Terms-and-Conditions/Click-and-Collect', '_blank')}
              >
                Terms & Conditions
              </span>.
            </p>
          </div>
          <div className='w100' style={{ textAlign: 'center', marginTop: 8 }}>
            <button
              disabled={currentlySubmittingPayment ||
                (!fields.number || fields.number.isEmpty || fields.number.isValid === false) ||
                (!fields.expirationDate || fields.expirationDate.isEmpty || fields.expirationDate.isValid === false) ||
                (!fields.cvv || fields.cvv.isEmpty || fields.cvv.isValid === false)}
              id='payButton'
              className="btn btn-primary"
              onClick={submitPayment}>
              Pay {config.CURRENCY_SYMBOL}{grandTotal}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayByCard;