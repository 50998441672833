import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import CustomiseHeader from '../components/CustomiseHeader';
import CustomiseSection from '../components/CustomiseSection';
import { calcSubTotal, addParentReferences, dereffItems, applyDefaultSubProducts } from '../../../helpers/basketHelpers';
import Layout from '../../../components/Layout';
import hash from 'object-hash';
import { useGetSingleMenuItemQuery } from '../../../services/restaurant.api';
import { historyMW } from '../../../helpers/routingHelpers';
import PopupBannerController from '../../../components/PopupBannerController';
import { useAddToBasketMutation, useUpdateBasketMutation } from '../../../services/basket.api';
import { freshRelevance } from '../../../helpers/commonHelpers';
import { config } from '../../../config';
const dietIcon = ['hot', 'gluten free', 'vegan', 'vegetarian'];

export const Customise = ({ restaurantId, quantity, setQuantity, refreshBasket }) => {
  const basketItems = useSelector(state => state.basket.items);
  const baseSelections = useSelector(state => state.menu.baseSelections);
  const outOfStockItems = useSelector(state => state.menu.outOfStockItems);
  const { isDelivery } = useSelector(state => state.basket);
  const { inApp } = useSelector(state => state.session);
  const { itemId } = useParams();
  const [item, setItem] = useState();
  const [customisedObj, setCustomisedObj] = useState({});
  const { width: screenWidth } = useWindowDimensions();
  const history = useHistory();
  const [invalidSections, setInvalidSections] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(0);
  const location = useLocation();
  const customID = location.state?.customID;
  const editQuantity = location.state?.quantity;
  const locationMenuItem = location.state?.menuItem;
  const { items, bundles, numberOfItems } = useSelector(state => state.basket);
  const [addToBasket] = useAddToBasketMutation();
  const [updateBasket] = useUpdateBasketMutation();
  const duoRomanaId = '10000012787';
  const isDuoRomana = item?.id === duoRomanaId;
  const pageRef = useRef(null);

  const addItemToBasket = async () => {
    const sections = Array.from(document.getElementsByClassName('customise-section'));
    const firstInvalidSectionNode = sections.filter(el => invalidSections.find(s => el.dataset.sectionErrorId === s))[0];
    if (invalidSections.length && firstInvalidSectionNode) {
      window.scrollTo({ top: window.pageYOffset + firstInvalidSectionNode.getBoundingClientRect().top - (window.innerHeight / 2) + 200, behavior: 'smooth', block: 'center' });
      setShowErrors(true);
    } else if (customID) {
      await updateBasket({
        restaurantId,
        numberOfItems,
        items: items.map(item => item.customID === customID ? { ...customisedObj, quantity, customID: hash(customisedObj) } : item),
        bundles
      });
      refreshBasket();
      historyMW.push('/basket', isDelivery, history);
    } else {
      await addToBasket([{ ...customisedObj, calculatedCost: currentPrice * quantity, quantity, customID: hash(customisedObj), restaurantId }]);
      freshRelevance('pageChange', null, { 'sendBeacon': true });
      refreshBasket();
      historyMW.push(`/menu/${restaurantId}`, isDelivery, history);
    }
  };

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    });
    if (editQuantity) {
      setQuantity(editQuantity);
    }
  }, []);

  const { data: menuItem, isLoading } = useGetSingleMenuItemQuery({ id: restaurantId, itemId }, { skip: !restaurantId || locationMenuItem });

  useEffect(() => {
    const item = menuItem || locationMenuItem;
    if (!isLoading || item) {
      if (item) {
        const itemWithParents = addParentReferences(item);
        setItem(itemWithParents);
      } else if (restaurantId) {
        historyMW.push(`/menu/${restaurantId}`, isDelivery, history);
      }
    }
  }, [menuItem, isLoading, locationMenuItem]);

  useEffect(() => {
    if (customID) {
      const basketItem = basketItems.find(el => el.customID === customID);

      if (basketItem) {
        setCustomisedObj(dereffItems(basketItem));
      } else {
        historyMW.push(`/menu/${restaurantId}`, isDelivery, history);
      }
    }
    else if (item?.id !== customisedObj?.id) {
      resetItem();
    }

  }, [item]);

  const resetItem = () => {
    const baseSelectionOverride = baseSelections.find(b => b.product === item.id);
    if (baseSelectionOverride) {
      item.subProducts[0].defaultSubProduct = baseSelectionOverride.base;
    }
    const defaultItem = applyDefaultSubProducts(item);
    const dereffedItem = dereffItems(defaultItem);
    setCustomisedObj({ ...dereffedItem, customID: customID });
  };

  useEffect(() => {
    if (customisedObj) {
      customisedObj.calcSubTotal && setCurrentPrice(customisedObj.calcSubTotal());
    }
  }, [customisedObj]);
  // console.log(customisedObj)

  return (
    <Layout
      title1='Customise'
      title2={'your selection'}
      section={'is-customisation'}
      titleColour='rose-100'
      subTitleColour='rose-100'
      backgroundColour='#331A1F'
      backButton={() => history.goBack()}
      noImage
      isCustomise
    >
      <div className='customise-container' ref={pageRef}>
        <CustomiseHeader
          dietIcon={dietIcon}
          name={item?.name}
          description={item?.description}
          restaurantId={restaurantId}
          screenWidth={screenWidth}
          quantity={quantity}
          setQuantity={setQuantity}
          customisedItem={customisedObj}
          setShowErrors={setShowErrors}
          invalidSections={invalidSections}
          edit={!!customID}
          refreshBasket={refreshBasket}
          currentPrice={currentPrice}
          addToBasket={addItemToBasket}
          inApp={inApp}
          item={item}
        />
        {
          item?.subProducts.map((el, idx) => (

            <CustomiseSection
              key={idx}
              item={{ ...el, calcSubTotal }}
              NewComponent={CustomiseSection}
              result={customisedObj}
              setCustomisedObj={setCustomisedObj}
              invalidSections={invalidSections}
              setInvalidSections={setInvalidSections}
              showErrors={showErrors}
              resetItem={resetItem}
              outOfStockItems={outOfStockItems}
              isDuoRomana={isDuoRomana}
              duoRomanaId={duoRomanaId}
            />
          ))
        }
        <CustomiseHeader
          dietIcon={dietIcon}
          name={item?.name}
          restaurantId={restaurantId}
          screenWidth={screenWidth}
          quantity={quantity}
          setQuantity={setQuantity}
          customisedItem={customisedObj}
          setShowErrors={setShowErrors}
          invalidSections={invalidSections}
          edit={!!customID}
          refreshBasket={refreshBasket}
          lastHeader
          addToBasket={addItemToBasket}
          currentPrice={currentPrice}
          inApp={inApp}
          item={item}
        />
      </div>
      <PopupBannerController justifyEnd parentRef={pageRef}>
        <button className='checkout-btn btn btn-primary' style={{width: 'min-content'}} onClick={addItemToBasket}>
          Add to basket ({quantity}) - {config.BRAND === 'PE' ? '£' : '€'}{(currentPrice * quantity).toFixed(2)}
        </button>
      </PopupBannerController>
    </Layout>
  );
};

export default Customise;