import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from '../../../components/Layout';
import RestaurantCard from '../components/RestaurantCard';
import { SearchInput } from '../components/SearchInput';
import { config } from '../../../config.js';
import LoaderBlack from '../../../img/common/Loader_360x360.gif';
import imageRightBottomMobile from '../../../img/hero/hero-right-bottom-mobile.png';
import imageRightBottomDesktop from '../../../img/hero/hero-right-bottom-desktop.png';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { gtmEvent } from '../../../helpers/commonHelpers';

export const postcodeRegex = /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/;

const Collection = (props) => {
  const {
    isDelivery = false,
    searchProps: {
      searchTerm,
      searchResults,
      showSearchError,
      searchByText,
      searchByLatLong,
      handleRestaurantSelected,
      updateSearchTerm,
      isLoading,
      hasUserTyped
    },
    banners,
    backgrounds,
    setProspectiveRestaurant,
  } = props;
  const { inApp } = useSelector(state => state.session);
  const { width, isSmallScreen } = useWindowDimensions();
  const { isSmallScreen: isXsmallScreen } = useWindowDimensions(555);

  useEffect(() => {
    if (hasUserTyped) {
      const timeoutId = setTimeout(async () => {
        if (searchTerm && searchTerm.length) {
          searchByText(isDelivery);
        }
      }, 1000);
      return () => clearTimeout(timeoutId);
    } else if (searchTerm?.length && !searchResults) {
      searchByText(isDelivery);
    }
  }, [searchTerm, hasUserTyped]);

  useEffect(() => {
    const searchOnEnter = (e) => {
      if (e.key === 'Enter') {
        searchByText(isDelivery);
        const inputElement = document.getElementById('searchInput');
        if (inputElement) {
          inputElement.blur();
        }
      }
    };
    window.addEventListener('keyup', searchOnEnter);
    return () => {
      window.removeEventListener('keyup', searchOnEnter);
    };
  }, [searchByText, searchTerm]);

  useEffect(() => {
    if (inApp) {
      if (searchResults?.restaurants?.length > 0) {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'NAV_BAR', payload: 'true' }));
      } else {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'NAV_BAR', payload: 'false' }));
      }
    }
  }, [searchResults]);

  useEffect(() => {
    if (inApp) {
      const hasRestaurants = searchResults?.restaurants?.length > 0;
      const event = new CustomEvent('updateNavBar', { detail: { showClose: hasRestaurants } });
      window.dispatchEvent(event);
    }
  }, [searchResults, inApp]);

  useEffect(() => {
    gtmEvent(isDelivery ? 'delivery_homepage' : 'collection_homepage', { channel: inApp ? 'App' : 'Web' });
  }, []);

  const brandTitle = config.BRAND == 'PE'
    ? `Find your nearest PizzaExpress open for ${isDelivery ? 'Delivery' : 'Collection'}.`
    : `Find your nearest Milano open for ${isDelivery ? 'Delivery' : 'Collection'}.`;
  const backgroundImage = isXsmallScreen ? backgrounds?.Mobile : backgrounds?.Desktop;

  return (
    <Layout
      title={isDelivery ? 'PizzaExpress Delivers' : 'Collection'}
      subtitle={brandTitle}
      heroPaddingSize={'is-xsmall'}
      collapsePadding
      sectionSize='is-small'
      banners={banners}
      heroBgImage={
        !isSmallScreen ? 
        <div style={{
          position: 'absolute',
          width: width >= 1440 ? width : width + ((1440 - width) / 2),
          height: '100%',
          backgroundImage: `url(${imageRightBottomDesktop})`,
          backgroundSize: 'contain',
          backgroundPositionX: 'right',
          backgroundRepeat: 'no-repeat'
        }} />
        : null
      }
      bgImage={backgroundImage}
      isFluid
    >
      <div className={`column is-8 is-offset-2 search-container-container ${searchResults?.restaurants?.length ? 'isSearching' : ''}`}>
        <div className={`block pt-5 searchContainer ${backgroundImage ? 'has-image' : ''}`}>
          <div className="columns is-flex-wrap-wrap">
            <div className="column is-12 is-flex is-justify-content-center" style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
              <div className="content" style={{ maxWidth: '640px', width: '640px', overflowY: 'auto', padding: 2 }}>
                <SearchInput
                  label={`Enter your postcode ${isDelivery ? '' : 'or location'}`}
                  errorMessage={searchResults?.error ? searchResults.error : ''}
                  valueCallback={updateSearchTerm}
                  value={searchTerm}
                  onLocationClick={() => searchByLatLong(isDelivery)}
                  isError={showSearchError}
                  placeholder='Search location'
                  id='searchInput'
                  handleClick={() => searchByText(isDelivery)}
                  showIcon
                />
              </div>
            </div>

            {isLoading &&
              <div style={{ flexGrow: 1, alignContent: 'center', flexDirection: 'column', display: 'flex', alignItems: 'center', paddingTop: '128px', paddingBottom: '128px' }}>
                <img src={LoaderBlack} style={{ width: 64, height: 64 }} />
              </div>
            }

            {!isLoading && searchResults?.restaurants?.length ? <div className="column is-12  is-flex is-justify-content-center search-container-results">
              <div className="content" style={{ maxWidth: '640px', width: '640px' }}>
                {searchResults?.restaurants?.filter(r => isDelivery ? true : !r.isCollectionHidden).map((restaurant, i) => (
                  <RestaurantCard id={'restaurantCard' + '-' + i} key={restaurant.id} restaurant={restaurant} handleRestaurantSelected={handleRestaurantSelected} setProspectiveRestaurant={setProspectiveRestaurant} />
                ))}
                <div style={{ height: 10, width: 10 }} />
              </div>
            </div> : null}

          </div>
        </div>
        {/* The following is required to preload the spinner. */}
        <img src={LoaderBlack} style={{ width: 0, height: 0 }} />
      </div>
    </Layout >
  );
};

export default Collection;
