import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import InputField from '../../../components/InputField';
import PasswordField from '../../../components/PasswordField';
import Captcha from '../../checkout/../checkout/components/Captcha';
import errorIcon from '../../../img/icons/error.svg';
import rewardsIcon from '../../../img/icons/rewards.svg'
import chevronIcon from '../../../img/icons/ChevronUpThinWhite.svg'
import checkoutIcon from '../../../img/icons/checkout.svg'
import offersIcon from '../../../img/icons/offers.svg'
import Layout from '../../../components/Layout';
import { useSelector } from 'react-redux';
import { useLoginMutation } from '../../../services/customer.api';
import { useBreakPoint } from '../../../hooks/useBreakPoint';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { historyMW } from '../../../helpers/routingHelpers';
import checked from '../../../img/icons/checked.svg';
import { useLocation } from 'react-router-dom';
import { freshRelevance, gtmEvent } from '../../../helpers/commonHelpers';
import { config } from '../../../config.js';
import SimpleHero from '../../../components/SimpleHero.jsx';

const brand = config.BRAND;

const Login = ({ onContinue }) => {
  const [inputValues, setInputValues] = useState({ emailAddress: '', password: '', rememberMe: false });
  const [inputErrors, setInputErrors] = useState({ emailAddress: '', password: '' });
  const [captchaToken, setCaptchaToken] = useState('');
  const captchaEnabled = useSelector(state => state.session?.captcha);
  const [loginCollapsed, setLoginCollapsed] = useState(true);

  const { restaurant, features, checkoutCompletePath, inApp } = useSelector(state => state.session);
  const { isDelivery } = useSelector(state => state.basket);

  const { width } = useWindowDimensions();
  const isSmallestScreen = useBreakPoint(width, 376);
  const isSmallerScreen = useBreakPoint(width, 466);
  const isSmallScreen = useBreakPoint(width, 555);
  const history = useHistory();
  const location = useLocation();
  const fromMenu = location?.search?.includes('menu=true');
  const captchaRef = useRef();

  const joinClubText = isSmallestScreen
    ? 'Join the PizzaExpress club for free!'
    : 'Why miss out? Join the PizzaExpress club for free!';

  // Used to verify captcha
  const turnstileOptions =
  {
    sitekey: config.BRAND === 'PE' ? '0x4AAAAAAABTQSnqtxCvaoxE' : '0x4AAAAAAABW8nl8IYRG8tCt',
    callback: (token) => {
      setCaptchaToken(token);
    }
  };

  useEffect(() => {
    if (captchaEnabled && (isSmallScreen || loginCollapsed)) {
      // References a component to where a Captcha should be displayed and renders it
      const ref = captchaRef;
      renderTurnstile(ref, turnstileOptions);
    }
    if (location?.state) {
      setInputValues({ emailAddress: location.state?.emailAddress, password: '', rememberMe: false });
    }
  }, [captchaEnabled, isSmallScreen, loginCollapsed]);

  useEffect(() => {
    gtmEvent(isDelivery ? 'delivery_login_page' : 'collection_login_page', { channel: inApp ? 'App' : 'Web' });
  }, []);

  const renderTurnstile = (ref, turnstileOptions) => {
    if (!ref.current) return;
    window.turnstile?.render(ref.current, turnstileOptions);
  };

  // Clears the existing captcha and regenerates
  const resetCaptcha = () => {
    if (!captchaEnabled) return;
    setCaptchaToken('');
    const ref = captchaRef;
    if (ref.current) {
      ref.current.innerHTML = '';
      renderTurnstile(ref, turnstileOptions);
    }
  };

  const email =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [loginUser, { isLoading: isLoggingInUser }] = useLoginMutation();

  const handleChange = useCallback((name, value) => {
    setInputValues({ ...inputValues, [name]: value });
  });

  const handleError = useCallback((name, value) => {
    setInputErrors({ ...inputErrors, [name]: value });
  });

  const login = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let emailError = ''; let passwordError = '';
    if (inputValues.emailAddress === '') emailError = 'Please enter a valid email';
    if (!email.test(inputValues.emailAddress) && inputValues.emailAddress !== '') {
      emailError = 'Please enter a valid email';
    }
    if (inputValues.password === '') passwordError = 'Please enter a valid password';

    if (emailError || passwordError) {
      setInputErrors({ emailAddress: emailError, password: passwordError });
    } else {
      const { data } = await loginUser({ ...inputValues, CaptchaToken: captchaToken });
      if (!data) {
        setInputErrors({ emailAddress: ' ', password: ' ', general: 'Something went wrong. (Error in RTK)' });
        resetCaptcha();
      }

      if (data.customerSessionToken && data.customerSessionToken.token) {
        gtmEvent(isDelivery ? 'delivery_login' : 'Collection_login', { channel: inApp ? 'App' : 'Web' });
        freshRelevance('pageChange', null, { 'sendBeacon': true });
        setInputErrors({ emailAddress: '', password: '' });
        onContinue({ ...data, emailAddress: inputValues.emailAddress }, checkoutCompletePath);
      } else {
        setInputErrors({ emailAddress: ' ', password: ' ', general: data.errorList.errors[0].description });
        resetCaptcha();
      }
    }
  };

  const join = () => {
    gtmEvent('handleJoin', { channel: inApp ? 'App' : 'Web' });
    freshRelevance('pageChange', null, { 'sendBeacon': true });
    historyMW.push(`/checkout/join${fromMenu ? '/true' : ''}`, isDelivery, history);
  };

  const guest = () => {
    gtmEvent('collection_checkout_details', { channel: inApp ? 'App' : 'Web' });
    historyMW.push('/checkout/guest', isDelivery, history);
    freshRelevance('pageChange', null, { 'sendBeacon': true });
  };

  const reset = () => {
    historyMW.push('/forgottenPassword', isDelivery, history);
    freshRelevance('pageChange', null, { 'sendBeacon': true });
  };

  window.enableLoginButtonForTest = () => {
    setCaptchaToken('MetfSW3YY436A1UFDjmV');
    return 'OK!';
  };

  return (
    <Layout
      backgroundColour='#1C1A1A'
      containerBreakpoint="desktop"
      hideHero
      sectionSize={isSmallScreen ? 'is-padless-top' : 'is-xsmall'}
      isLogin
      isSmallerTitle
    >
      <>
        {!isSmallestScreen && (
          <SimpleHero title="Secure Checkout" customClass={'collection-time-title'} customStyle={{
            marginTop: (isSmallScreen && !isSmallerScreen)
              ? '2rem'
              : (isSmallerScreen
                ? '1rem'
                : '0rem')
          }} />
        )}
        <div style={{ margin: 'auto', width: 'auto', alignItems: 'center', paddingBottom: '1rem', paddingTop: isSmallScreen ? '0rem' : '2rem' }}>
          <div className={brand === 'PE' ? 'login-row' : ''} style={{ marginTop: isSmallestScreen ? '3rem' : '0rem' }}>
            {isSmallScreen &&
              <div style={{ textAlign: 'center' }}>
                <div>
                  <button
                    id='logInCollapseExpandButton'
                    className="btn btn-expand-collapse"
                    style={{ width: 216 }}
                    onClick={() => setLoginCollapsed(!loginCollapsed)}>
                    <span style={{ marginRight: '8px' }}>Log in</span>
                    <i>
                      <img src={chevronIcon} alt="Chevron Icon" style={{ transform: loginCollapsed ? 'rotate(180deg)' : '' }} />
                    </i>
                  </button>
                </div>
              </div>
            }
            {((isSmallScreen && !loginCollapsed) || (!isSmallScreen && !isSmallestScreen)) &&
              <form onSubmit={login} className='login-form'>
                {!isSmallScreen &&
                  <p className='title join-pe' style={{ lineHeight: '100%' }}>LOG IN</p>}

                <div className="mb-5">
                  <InputField
                    title="Email"
                    name="emailAddress"
                    value={inputValues.emailAddress}
                    valueCallback={handleChange}
                    error={inputErrors.emailAddress}
                    errorCallback={handleError}
                    valueType="email"
                    type="email"
                    required={true}
                    id='emailAddressInput'
                    placeHolder='Enter your email'
                  />
                </div>
                <div className="mb-5">
                  <PasswordField
                    title="Password"
                    name="password"
                    value={inputValues.password}
                    valueCallback={handleChange}
                    error={inputErrors.password}
                    errorCallback={handleError}
                    required={true}
                    disableChecks={true}
                    id='passwordInput'
                  />
                </div>
                {inputErrors.general && (
                  <div className="level is-mobile">
                    <div className="level-item is-narrow is-shrinkable is-align-top" style={{ marginRight: 8 }}>
                      <i className="icon is-small">
                        <img src={errorIcon} alt="error" />
                      </i>
                    </div>
                    <div className="level-item is-growable is-shrinkable is-justify-content-flex-start is-align-top">
                      <div className="error-message">{inputErrors.general}</div>
                    </div>
                  </div>
                )}

                <Captcha captchaRef={captchaRef}></Captcha>

                <div>
                  <div className="mb-5 is-flex is-flex-direction-column is-align-items-center" style={{ textAlign: 'center', gap: 24 }}>
                    <input
                      type="submit"
                      id='loginButton'
                      className="btn btn-primary w10"
                      disabled={(isLoggingInUser || (!captchaToken && captchaEnabled))} value="Continue"
                    />
                  </div>
                  <div className="extra-info-container">
                    <label htmlFor='rememberMe' className={`checkbox ${inputValues.rememberMe && 'checked'}`} style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type='checkbox'
                        name="rememberMe"
                        onChange={(e) => handleChange(e.target.name, e.target.checked)}
                        id='rememberMe'
                      />
                      <i>
                        <img src={checked} className='check' style={{ transform: 'scale(1.4)' }} />
                      </i>
                      <label
                        style={{ userSelect: 'none', cursor: 'pointer' }}
                      >
                        Remember me
                      </label>
                    </label>
                    <input
                      id='forgottenPasswordButton'
                      className="link dm-sans forgot-password"
                      style={{ border: 'none', backgroundColor: 'transparent', textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={reset} value="Forgot password?" type="button" />
                  </div>
                </div>
              </form>
            }
            <div>
              {((!isSmallestScreen || brand=== 'MI') && isSmallScreen)&&
                <div className="has-text-centered" style={{ marginTop: 10, marginBottom: 10 }}>
                  <p className="is-family-code" style={{ fontSize: isSmallScreen ? 16 : 18, fontWeight: 700 }}>or</p>
                </div>
              }
              {brand === 'PE' &&
                <div className={"create-account-card"}>
                  <p className='join-pe-club'>{joinClubText}</p>
                  <div className='incentive-icon-row'>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <i className='incentive-icon-container'>
                        <img src={rewardsIcon} alt="rewards" />
                      </i>
                      <p className='incentive-text'>Earn free <br />rewards </p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <i className='incentive-icon-container'>
                        <img src={checkoutIcon} alt="checkout" />
                      </i>
                      <p className='incentive-text'>Faster <br />checkout</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <i className='incentive-icon-container'>
                        <img src={offersIcon} alt="offers" />
                      </i>
                      <p className='incentive-text'>Exclusive <br />offers</p>
                    </div>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <div className="block">
                      <button
                        id='joinButton'
                        className="btn btn-tertiary"
                        onClick={join}>
                        {features.loyalty ? 'Create an account' : brand === 'PE' ? 'Join' : 'Register'}
                      </button>
                    </div>
                  </div>
                </div>
              }
              {
                !isDelivery && !fromMenu &&
                // <div className={brand === 'PE' ? 'mi-account-parent-class' : ''}>
                <div>
                  {(brand === 'MI' && !isSmallScreen) &&
                    <>
                      <div className="has-text-centered" style={{ marginTop: 15, marginBottom: 15 }}>
                        <p className="is-family-code" style={{ fontSize: isSmallScreen ? 16 : 18, fontWeight: 700 }}>or</p>
                      </div>                      
                      <div className='mi-account-option-row'>
                        <div className={'account-child-class'}>
                          <button
                            id='joinButtonMI'
                            style={{ width: 216 }}
                            className="btn btn-primary"
                            onClick={join}>
                            Create an account
                          </button>
                        </div>
                        <div className='account-child-class'>
                          <button
                            id='guestCheckoutButton'
                            style={{ width: 216 }}
                            className={'btn btn-secondary'}
                            onClick={guest}>
                            Guest checkout
                          </button>
                        </div>
                      </div>

                    </>
                  }
                  {(brand === 'PE' || isSmallScreen) &&
                    <>
                    {brand === 'MI' &&
                      <div className={'account-child-class'}>
                      <button
                        id='joinButtonMI'
                        style={{ width: 216 }}
                        className="btn btn-primary"
                        onClick={join}>
                        Create an account
                      </button>
                      </div>
                    }

                      {(!isSmallestScreen || brand === 'MI') &&
                        <div className="has-text-centered" style={{ marginTop: 10, marginBottom: 10 }}>
                          <p className="is-family-code" style={{ fontSize: isSmallScreen ? 16 : 18, fontWeight: 700 }}>or</p>
                        </div>
                      }
                      <div className='account-child-class'>
                        <button
                          id='guestCheckoutButton'
                          style={{ width: 216 }}
                          className={'btn btn-secondary'}
                          onClick={guest}>
                          Guest checkout
                        </button>
                      </div>
                    </>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </>
    </Layout>);
};

export default Login;
