import React from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { Container } from 'react-bulma-components';
import chevronLeftWhite from '../img/icons/ChevronLeftWhite.svg';
import chevronLeft from '../img/icons/ChevronLeft.svg';
import { freshRelevance, gtmEvent } from '../helpers/commonHelpers';
import { config } from '../config';

const restaurantSelectionEndPoints = ['/', '/collection/', '/delivery/', '/collection', '/delivery'];

const Hero = ({
  backgroundColour,
  title,
  titleStyles,
  title1,
  title2,
  title3,
  subtitle,
  ctaCopy,
  copyColour,
  headerType,
  titleColour,
  subTitleColour,
  backButton,
  allergens,
  heroWidthHalf,
  heroPaddingSize,
  paddingTop,
  customStyles,
  isSmallerTitle,
  bgImage,
  extraVerticalPadding,
  isCustomise,
  isPayment,
  isSticky,
  isBasket,
  inApp,
  disableBackButton
}) => {
  const { pathname } = useLocation();
  const { isDelivery } = useSelector(state => state.basket);

  const Title = (title) => {
    const regexStr = /.?[PF]/;
    return title.split('\n').map((str, i) => {
      if (str.match(regexStr)) {
        return (
          <span key={'title-' + i} style={{ display: 'block' }}>
            <span className="special">{str.charAt(0)}</span>
            {str.substring(1)}
          </span>
        );
      } else {
        return <span key={'title-' + i} style={titleStyles}>{str}</span>;
      }
    });
  };

  const allergenClicked = () => {
    gtmEvent(isDelivery ? 'Delivery_Allergens_Link' : 'Collection_Allergens_Link', { channel: inApp ? 'App' : 'Web' });
    freshRelevance('pageChange', null, { 'sendBeacon': true });
    window.open(config.BRAND === 'PE' ? 'https://www.pizzaexpress.com/allergens-and-nutritionals' : 'https://www.milano.ie/allergens-and-nutritionals', '_blank');
  };

  const restaurantSelectionPage = restaurantSelectionEndPoints.includes(pathname);

  return (
    <section
      data-testid="hero-section"
      className={`section hero 
        ${extraVerticalPadding ? 'extra-vertical-padding' : ''}
        ${headerType ? headerType : ''} 
        ${customStyles ? customStyles : ''}
        ${copyColour ? `has-text-${copyColour}` : ''}
        ${backgroundColour ? `has-background-${backgroundColour}` : ''}
        ${heroPaddingSize ? heroPaddingSize : ''}
        ${isCustomise ? 'customise' : ''}
        ${(!inApp && isPayment) ? 'payment' : ''}
        ${pathname?.includes('login') || pathname?.includes('forgottenPassword') || pathname?.includes('verifyemail') || pathname?.includes('resetPassword') || pathname?.includes('passwordResetConfirmation') ? 'login' : ''}
        ${isSticky ? 'isSticky' : ''}
      `}
    >
      {
        !inApp && !isBasket && bgImage &&
        <>
          {bgImage}
        </>
      }
      <div className={`hero-body is-relative ${backButton ? 'has-back-button' : ''} ${inApp ? 'in-app' : ''} ${(restaurantSelectionPage) ? 'restaurant-search' : ''}`} style={{ ...paddingTop ? { paddingTop } : {}, overflow: 'hidden' }}>

        <Container className="hero-content is-fluid" style={{position: 'static'}}>
          <div className="is-centered has-text-centered m-0" style={{position: 'relative'}}>

            {backButton && !inApp &&
              <button
                onClick={backButton}
                className={'back-btn'}
                disabled={disableBackButton}
              >
                <img src={isPayment || isBasket ? chevronLeft : chevronLeftWhite} alt="back" style={{ width: 24, height: 24 }} />
                <span style={{ color: isPayment || isBasket ? '#1c1a1a' : 'white' }} className='pl-2 back-btn-text'>Back</span>
              </button>
            }

            <div className={`title-hero ${heroWidthHalf ? 'is-6' : backButton ? 'is-10' : 'is-12'} p-0`}>
              <div className="block content is-flex-direction-column is-justify-content-center is-align-items-center">
                {(title || title1) && (
                  <h1
                    data-testid="hero-title"
                    className={`
                      title is-spaced is-uppercase
                      ${titleColour ? 'has-text-' + titleColour : ''}
                      ${isSmallerTitle ? 'is-smaller-title' : ''}
                      ${isBasket ? 'is-basket' : ''}
                      ${(inApp && !restaurantSelectionPage) ? 'in-app' : ''}
                      ${(restaurantSelectionPage) ? 'restaurant-search' : ''}
                      
                    `}
                    style={{ lineBreak: 'strict' }}
                  >
                    {Title(title || title1)} <span style={{ whiteSpace: 'nowrap' }}>{title2}</span>
                  </h1>
                )}
                {(title3 && !inApp) && (
                  <h2 className={`title is-size-2 is-spaced ${titleColour && 'has-text-' + titleColour}`} style={{ lineBreak: 'strict' }}>
                    {Title(title3)}
                  </h2>
                )}
                {subtitle && (
                  <h4
                    data-testid="hero-subtitle"
                    className={`subtitle oop-in-app
                    ${allergens || ctaCopy ? '' : 'mb-0-desktop mb-0-tablet mb-0-mobile'}

                    ${subTitleColour ? 'has-text-' + subTitleColour : ''}`}
                  >
                    {subtitle}
                  </h4>
                )}
                {(allergens || ctaCopy) && <div className='allergen-link-wrapper is-flex-direction-column'>

                  {allergens && (
                    <div>
                      <a onClick={allergenClicked} className={'titleLink hero-allergen'}>
                        Allergen Information
                      </a>
                    </div>
                  )}
                </div>}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default Hero;
