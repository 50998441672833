import React, { useCallback, useEffect, useRef, useState } from 'react';
import SimpleHero from '../../../components/SimpleHero';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useBreakPoint } from '../../../hooks/useBreakPoint';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import DropDown from '../../../components/DropDown';
import DropDownField from '../../../components/DropDownField';
import InputField from '../../../components/InputField';
import InfoPopOver from '../../../components/InfoPopOver';
import { config } from '../../../config';
import { ChangePostcodeModalWrapper } from '../../../helpers/modalHelpers';
import { setCollectionTime, setAddress } from '../../../store/sessionSlice';
import { historyMW } from '../../../helpers/routingHelpers';
import chevronDown from '../../../img/icons/chevronDown.svg';
import { useUpdateBasketDeliveryMutation } from '../../../services/basket.api';
import { useLazyGetAddressesFromPostcodeQuery } from '../../../services/customer.api';
import uberLogo from '../../../img/Delivered_Horizontal_white.svg';
import { useSafeState } from '../../../hooks/useIsMounted';
import { gtmEvent } from '../../../helpers/commonHelpers';

const DELIVERY_MESSAGE = 'Don\'t use pickup manager. ';

const initialValues = {
  deliveryAddressId: '',
  driverInstructions: '',
};

export const getDiscountsTotal = (discounts) => discounts.reduce((acc, d) => (acc + d.amount), 0);

export const valueWithAppliedRewards = (value, discounts) => {
  const rewardTotal = getDiscountsTotal(discounts);
  const subTotalAppliedRewards = value - rewardTotal;
  return subTotalAppliedRewards;
};

const DeliveryDetails = ({ canProceed, setTCError, isRecieveOffers, deliveryAddress }) => {
  const { inApp } = useSelector(state => state.session);
  const {
    isDelivery,
    delivery,
    deliveryFee,
    discounts,
    serviceFee,
    smallOrderFee,
    subTotal,
    total,
    voucherCodes,
    voucherReduction,
  } = useSelector(state => state.basket);
  const deliveryFeeItem = delivery?.deliveryFeeItem;
  const deliveryFeeIsFree = deliveryFeeItem && (discounts?.some(discount => discount.id === deliveryFeeItem.customID || discount.id === deliveryFeeItem.id));

  const [updateBasketDelivery] = useUpdateBasketDeliveryMutation();
  const [getAddressesFromPostcode] = useLazyGetAddressesFromPostcodeQuery();
  const history = useHistory();
  const [inputValues, setInputValues] = useState({
    deliveryAddressId: delivery?.deliveryAddressId || '',
    driverInstructions: delivery?.driverInstructions?.trim() || '',
  });
  const [inputErrors, setInputErrors] = useState(initialValues);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addresses, setAddresses] = useSafeState([]);
  const [prevPostCode, setPrevPostCode] = useState('');
  const [serviceFeeText, setServiceFeeText] = useState('');
  const [smallOrderFeeText, setSmallOrderFeeText] = useState('');
  const { width } = useWindowDimensions();
  const isSmallScreen = useBreakPoint(width, 555);
  const addressLine1Ref = useRef(null);
  const infoSubtotalContainer = useRef({});
  const infoDeliveryContainer = useRef({});
  const infoServicefeeContainer = useRef({});
  const infoSmallOrderContainer = useRef({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCollectionTime(''));

    if (deliveryAddress) {
      setInputValues(prevValue => ({ ...prevValue, deliveryAddressId: deliveryAddress }));
    }
    console.log(deliveryAddress)
    switch (config.BRAND) {
      case 'MI':
        setServiceFeeText('This goes to Milano to cover the cost of our technology platform, adding new features, and providing great customer service. The fee is 5% of your basket after promotions or discounts are applied. The minimum you’ll be charged is €0.75, the maximum is €0.99.');
        setSmallOrderFeeText('When your order is less than the restaurant’s minimum, we add a small order fee of up to €2 to orders under €15. To remove it, just add more to your basket.');
        break;
      case 'PE':
        setServiceFeeText('This goes to PizzaExpress to cover the cost of our technology platform, adding new features, and providing great customer service. The fee is 5% of your basket after promotions or discounts are applied. The minimum you’ll be charged is £0.75, the maximum is £0.99.');
        setSmallOrderFeeText('When your order is less than the restaurant’s minimum, we add a small order fee of up to £2 to orders under £15. To remove it, just add more to your basket.');
        break;
      default:
        break;
    }

    const getAddresses = async () => {
      let addressList;
      const foundAddresses = await getAddressesFromPostcode({ postcode: delivery.userPostcode });
      if (!foundAddresses.data.isSuccess) {
        setAddresses([
          { label: 'No addresses found, please check your postcode', value: '', disabled: true }
        ]);
        return;
      }

      addressList = foundAddresses.data.items.map(address => ({ label: address.text, value: address.id }));
      setAddresses([
        ...addressList
      ]);

      if (inputValues.deliveryAddressId !== '' && !addressList.find(address => address.value === inputValues.deliveryAddressId)) {
        setInputValues(prevValue => ({ ...prevValue, deliveryAddressId: '' }));
      }
    };
    
    if (delivery.userPostcode !== prevPostCode) {
      getAddresses();
    }
    setPrevPostCode(delivery.userPostcode);
  }, [deliveryAddress, delivery.userPostcode]);

  const handleChange = useCallback((name, value) => {
    if (name === 'driverInstructions') {
      setInputValues({ ...inputValues, [name]: value.slice(0, 280 - DELIVERY_MESSAGE.length).replace(/(\r\n|\n|\r)/gm, '') });
    } else {
      setInputValues({ ...inputValues, [name]: value });
    }
  });

  const handleError = useCallback((name, value) => {
    setInputErrors({ ...inputErrors, [name]: value });
  });

  const handleAddressChange = useCallback((name, value) => {
    setInputValues({
      ...inputValues,
      deliveryAddressId: value
    });
    setInputErrors(initialValues);
    dispatch(setAddress(value));
  }, [dispatch, inputValues, setInputErrors]);

  const continueToPayment = async () => {
    if (canProceed === false) {
      setTCError(true);
      return;
    }
    if (inputValues.deliveryAddressId.length === 0) {
      setInputErrors(errors => ({ ...errors, deliveryAddressId: 'Please select an address' }));
      const element = document.getElementById('address-selector');
      const y = element.getBoundingClientRect().top + window.pageYOffset - 350;
      window.scrollTo({ top: y, behavior: 'smooth' });
      return;
    }
    const { deliveryAddressId, driverInstructions } = inputValues;

    await updateBasketDelivery({
      ...delivery,
      deliveryAddressId: deliveryAddressId,
      driverInstructions: driverInstructions,
      searchTerm: delivery.userPostcode
    });
    gtmEvent(isDelivery ? 'delivery_select_address' : 'collection_select_address', { channel: inApp ? 'App' : 'Web' });
    if (isRecieveOffers)
      historyMW.push('/checkout/verifyemail', isDelivery, history);
    else
      historyMW.push('/payment', isDelivery, history);
  };

  return (
    <div role="delivery-details-root" style={{ width: '100%' }}>
      <SimpleHero title="delivery details" customClass={`delivery-details-title ${inApp ? 'in-app' : ''}`} />
      <div className="details-content-wrapper">
        <p className="delivery-details">
          Delivery time: ASAP {`(${delivery?.deliveryQuote?.duration?.begin}-${delivery?.deliveryQuote?.duration?.end} mins)`}
        </p>
        <div className="is-flex is-justify-content-space-between mt-2">
          <p className="delivery-details">Delivering to: {delivery.userPostcode}</p>
          <p className='edit-btn is-pointer' onClick={() => setModalIsOpen(true)}>Change</p>
        </div>
        <div className="block">
          <div role="address-selector" className="column is-12 pb-0" ref={addressLine1Ref} style={{ paddingRight: 0, paddingLeft: 0 }} key="collectionTimeDropDown">
            <DropDown>
              <DropDownField
                title='Address'
                required
                name="address"
                icon={chevronDown}
                valueCallback={handleAddressChange}
                options={addresses}
                value={deliveryAddress || inputValues.deliveryAddressId}
                error={inputErrors.deliveryAddressId}
                id='address-selector'
                placeHolder='Choose your address'
                titleExtraIdentifier={'Choose your delivery address'}
              />
            </DropDown>
          </div>
          <div className="column is-12 pt-0" style={{ paddingRight: 0, paddingLeft: 0 }} key="driverInstructionsInput">
            <InputField
              title="Driver instructions (optional)"
              description="*Please do not include any allergen or dietary requests"
              name='driverInstructions'
              value={inputValues.driverInstructions}
              valueCallback={handleChange}
              error={inputErrors.driverInstructions}
              errorCallback={handleError}
              id='driverInstructionsInput'
              type="textarea"
            />
          </div>
          {isDelivery && (<div className="bill-part" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '0.5rem', marginBottom: isSmallScreen ? '1.5rem' : '3rem' }}>
            <img role="uber-logo" style={{ 'height': '3.75rem' }} src={uberLogo} alt='logo' />
          </div>)}
          {isDelivery && (
            <div className='bill-parts-wrapper'>
              <hr style={{ margin: '0 0 1rem 0', backgroundColor: 'transparent' }} />
              <div
                ref={infoSubtotalContainer}
                className="bill-part content"
              >
                <div>  
                  <span className='bill-part-label'>
                    Subtotal
                    {(voucherCodes?.length > 0 || discounts?.length > 0)
                          && (
                            <span className='small-text'>
                                (including discount)
                            </span>
                          )
                    }
                  </span>
                  <InfoPopOver
                    text="This subtotal reflects the total price of your order. It does not include delivery fee and service fee."
                    parentRef={infoSubtotalContainer}
                    padding={80}
                    textPadding={12}
                  />
                </div>
                {discounts?.length > 0
                  ? <span className="value sub-total" role="voucher-total-display-with-discounts">
                    {config.CURRENCY_SYMBOL}{valueWithAppliedRewards(subTotal - voucherReduction, discounts).toFixed(2)}
                  </span>
                  : <span className="value sub-total" role="voucher-total-display">
                    {config.CURRENCY_SYMBOL}{(subTotal - voucherReduction)?.toFixed(2)}
                  </span>}
              </div>
              <hr style={{ margin: isSmallScreen ? '0 0 1rem 0' : '1rem 0', backgroundColor: 'rgba(215, 210, 203, 0.6)' }} />
              <div
                ref={infoDeliveryContainer}
                className="bill-part content"
              >
                <div>
                  <span className='bill-part-label'>
                    Delivery fee
                  </span>
                  <InfoPopOver
                    text="This goes to Uber Eats and covers the cost of delivery based on distance."
                    parentRef={infoDeliveryContainer}
                    padding={80}
                    textPadding={12}
                  />
                </div>
                <span className="value">
                  {
                    deliveryFeeIsFree ?
                      <span className="free">
                        {config.CURRENCY_SYMBOL}{deliveryFee?.toFixed(2)}
                      </span>
                      :
                      config.CURRENCY_SYMBOL + deliveryFee?.toFixed(2)
                  }
                </span>
              </div>
              <hr style={{ margin: isSmallScreen ? '0 0 1rem 0' : '1rem 0', backgroundColor: 'rgba(215, 210, 203, 0.6)' }} />
              <div
                ref={infoServicefeeContainer}
                className="bill-part content"
              >
                <div>
                  <span className='bill-part-label'>
                    Service fee
                  </span>
                  <InfoPopOver
                    text={serviceFeeText}
                    parentRef={infoServicefeeContainer}
                    padding={80}
                    textPadding={12}
                  />
                </div>
                <span className="value">
                  {config.CURRENCY_SYMBOL}{serviceFee?.toFixed(2)}
                </span>
              </div>
              {smallOrderFee ? ( 
                <>
                  <hr style={{ margin: isSmallScreen ? '0 0 1rem 0' : '1rem 0', backgroundColor: 'rgba(215, 210, 203, 0.6)' }} />
                  <div
                    ref={infoSmallOrderContainer}
                    className="bill-part content"
                  >
                    <div>
                      <span className='bill-part-label'>
                        Small order fee
                      </span>
                      <InfoPopOver
                        text={smallOrderFeeText}
                        parentRef={infoSmallOrderContainer}
                        padding={80}
                        textPadding={12}
                      />
                    </div>
                    <span className="value">
                      {config.CURRENCY_SYMBOL}{smallOrderFee?.toFixed(2)}
                    </span>
                  </div>
                </>
              ) : null}
              <div
                className="bill-part total content"
              >
                <span
                  className='bill-part-label'
                >Total</span>
                <span className="value total">{config.CURRENCY_SYMBOL}{total?.toFixed(2)}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="column is-12" style={{ paddingTop: '48px', textAlign: 'center' }}>
        <div id='continueButton' onClick={continueToPayment} className="btn btn-primary w10" style={{ margin: 'auto' }}>Continue to payment</div>
      </div>
      <ChangePostcodeModalWrapper isOpen={modalIsOpen} setIsOpen={setModalIsOpen} inApp={inApp} />
    </div>
  );
};

export default DeliveryDetails;
