import React, { useState, useEffect, useCallback, useRef } from 'react';
import SimpleHero from '../../../components/SimpleHero';
import InputField from '../../../components/InputField';
import DobPicker from '../../../components/DobPicker';
import DropDown from '../../../components/DropDown';
import DropDownField from '../../../components/DropDownField';
import PasswordField from '../../../components/PasswordField';
import checkSelected from '../../../img/icons/checked.svg';
import checkNotSelected from '../../../img/icons/unchecked.svg';
import PasswordChecker from '../components/PasswordChecker';
import Captcha from '../../checkout/../checkout/components/Captcha';
import { Redirect, useHistory, useParams } from 'react-router';
import Layout from '../../../components/Layout';
import CollectionTime from '../components/CollectionTime';
import GreenTickAndText from '../../../components/shared/GreenTickAndText';
import { useSelector } from 'react-redux';
import { useGuestLoginMutation, useRegisterUserMutation } from '../../../services/customer.api';
import errorIcon from '../../../img/icons/error.svg';
import { useLazyGetApplicationStateQuery } from '../../../services/application.api';
import { historyMW, pathMW } from '../../../helpers/routingHelpers';
import LoaderWhite from '../../../img/common/Loader_360x360_White.gif';

import { freshRelevance, gtmEvent } from '../../../helpers/commonHelpers';
import { config } from '../../../config.js';

const brand = config.BRAND;

const SignUp = ({ guest, onContinue }) => {
  const restaurantId = useSelector(state => state.session.restaurantId);
  const [guestSignIn, { isLoading: isSigningInGuest }] = useGuestLoginMutation();
  const [registerUser, { isLoading: isRegisteringUser }] = useRegisterUserMutation();
  const captchaEnabled = useSelector(state => state.session?.captcha);
  const { email, firstName, lastName, postCode, mobileNumber, dateOfBirth } = useSelector(state => state.basket);
  const { 1: { isLoading: isGettingApplicationState, isFetching: isFetchingApplicationState } } = useLazyGetApplicationStateQuery();
  const [inputValues, setInputValues] = useState({
    firstName: firstName || '',
    lastName: lastName || '',
    mobileNumber: mobileNumber || '',
    emailAddress: email || '',
    password: '',
    postcode: postCode || '',
    dob: {
      day: (+dateOfBirth?.split('/')[0]).toString() || '',
      month: (+dateOfBirth?.split('/')[1]).toString() || '',
      year: (+dateOfBirth?.split('/')[2]).toString() || '',
    },
  });
  const [inputErrors, setInputErrors] = useState({
    firstName: '',
    lastName: '',
    mobileNumber: '',
    emailAddress: '',
    password: '',
    postcode: '',
    dob: '',
    tandC: '',
  });
  const [isRecieveOffers, setIsRecieveOffers] = useState(false);
  const [optOut, setOptOut] = useState(false);
  const [tandC, setTandC] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');

  const history = useHistory();
  const { checkoutCompletePath, features, inApp } = useSelector(state => state.session);
  const { isDelivery } = useSelector(state => state.basket);

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailAddressRef = useRef(null);
  const mobileNumberRef = useRef(null);
  const dobRef = useRef(null);
  const postcodeRef = useRef(null);
  const passwordRef = useRef(null);
  const tandCRef = useRef(null);
  const masterErrorRef = useRef(null);
  const captchaRef = useRef();

  const { fromBanner } = useParams();

  // Used to verify captcha
  const turnstileOptions =
  {
    sitekey: config.BRAND === 'PE' ? '0x4AAAAAAABTQSnqtxCvaoxE' : '0x4AAAAAAABW8nl8IYRG8tCt',
    callback: (token) => {
      setCaptchaToken(token);
    }
  };

  useEffect(() => {
    if (captchaEnabled) {
      // References a component to where a Captcha should be displayed and renders it
      const ref = captchaRef;
      renderTurnstile(ref, turnstileOptions);
    }
    if (location?.state) {
      setInputValues({ emailAddress: location.state.emailAddress, password: '', rememberMe: false });
    }
  }, [captchaEnabled]);

  const renderTurnstile = (ref, turnstileOptions) => {
    window.turnstile.render(ref.current, turnstileOptions);
  };

  // Clears the existing captcha and regenerates
  const resetCaptcha = () => {
    setCaptchaToken('');
    const ref = captchaRef;
    if (ref.current) {
      ref.current.innerHTML = '';
      renderTurnstile(ref, turnstileOptions);
    }
  };

  const handleChange = useCallback((name, value, dobField) => {
    if (name === 'dob') {
      const newDob = { ...inputValues.dob, [dobField]: value };
      setInputValues({ ...inputValues, dob: newDob });
      if (newDob.day !== '' && newDob.month !== '' && newDob.year !== '') {
        setInputErrors({ ...inputErrors, dob: '' });
      }
    } else {
      setInputValues({ ...inputValues, [name]: value });
      setInputErrors({ ...inputErrors, [name]: '' });
    }
  });

  const handleError = useCallback((name, value) => {
    setInputErrors({ ...inputErrors, [name]: value });
  });

  const updateTanC = (value) => {
    setTandC(value);
    setInputErrors({ ...inputErrors, tandC: '' });
  };

  const scrollToError = (firstNameError, lastNameError, mobileNumberError, emailAddressError, passwordError, postcodeError, dobError, tandCError) => {
    if (firstNameError !== '') firstNameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    else if (lastNameError !== '') lastNameRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    else if (emailAddressError !== '') emailAddressRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    else if (mobileNumberError !== '') mobileNumberRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    else if (dobError !== '') dobRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    else if (postcodeError !== '') postcodeRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    else if (passwordError !== '') passwordRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    else if (tandCError !== '') tandCRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const continueToPayment = async () => {
    setProcessing(true);
    let firstNameError = '', lastNameError = '', mobileNumberError = '', emailAddressError = '', passwordError = '', postcodeError = '', dobError = '', tandCError = '';

    if (inputValues.firstName === '') firstNameError = 'Please enter your first name';
    if (inputValues.lastName === '') lastNameError = 'Please enter your last name';
    if (inputValues.mobileNumber === '') mobileNumberError = 'Please enter a valid mobile number';
    if (inputValues.emailAddress === '') emailAddressError = 'Please enter a valid email address';
    if (inputValues.password === '') passwordError = 'Please enter your password';
    if (inputErrors.password) passwordError = 'Please enter a valid password';
    if (inputValues.postcode === '') postcodeError = 'Please enter a valid postcode';
    if (inputValues.dob.day === '' || inputValues.dob.month === '' || inputValues.dob.year === '' ||
      isNaN(inputValues.dob.day) || isNaN(inputValues.dob.month) || isNaN(inputValues.dob.year)) {
      dobError = 'Please enter your date of birth';
    }
    if (!tandC) tandCError = 'Please accept the terms & conditions before you proceed.';

    if (inputErrors.firstName) firstNameError = 'Please enter a valid first name';
    if (inputErrors.lastName) lastNameError = 'Please enter a valid last name';
    if (inputErrors.mobileNumber) mobileNumberError = 'Please enter a valid mobile number';
    if (inputErrors.emailAddress) emailAddressError = 'Please enter a valid email address';
    if (inputErrors.password) passwordError = 'Please enter a valid password';
    if (inputErrors.postcode) postcodeError = 'Please enter a valid postcode';
    if (inputErrors.dob) dobError = 'Please enter a valid date of birth';
    if (!tandC) tandCError = 'Please accept the terms & conditions before you proceed.';

    const { day, month, year } = inputValues.dob;
    const dob = day && month && year ? `${day.length === 1 ? '0' + day : day}/${month.length === 1 ? '0' + month : month}/${year}` : null;

    if (guest && !isRecieveOffers) {
      if (firstNameError || lastNameError || mobileNumberError || emailAddressError || postcodeError || dobError || tandCError) {
        setInputErrors({
          firstName: firstNameError,
          lastName: lastNameError,
          mobileNumber: mobileNumberError,
          emailAddress: emailAddressError,
          postcode: postcodeError,
          dob: dobError,
          tandC: tandCError
        });
        scrollToError('', firstNameError, lastNameError, mobileNumberError, emailAddressError, '', postcodeError, dobError, tandCError);
      } else {
        let { data: registrationResponse } = await guestSignIn({ ...inputValues, dob, optIn: !optOut, tandC });
        if (registrationResponse.status == 'OK') {
          onContinue({ ...inputValues, dob, optIn: !optOut, tandC, ...registrationResponse }, true);
        }
        else {
          setInputErrors({ ...inputErrors, masterError: registrationResponse.errorDate.errors[0].description });
          masterErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    } else {
      if (firstNameError || lastNameError || mobileNumberError || emailAddressError || passwordError || postcodeError || dobError || tandCError) {
        setInputErrors({
          firstName: firstNameError,
          lastName: lastNameError,
          mobileNumber: mobileNumberError,
          emailAddress: emailAddressError,
          password: passwordError,
          postcode: postcodeError,
          dob: dobError,
          tandC: tandCError,
        });
        scrollToError(firstNameError, lastNameError, mobileNumberError, emailAddressError, passwordError, postcodeError, dobError, tandCError);
      } else {
        const { data: registrationResponse } = await registerUser({
          title: "",
          channel: isDelivery ? 'delivery' : 'collection',
          firstName: inputValues.firstName,
          lastName: inputValues.lastName,
          mobileNumber: inputValues.mobileNumber,
          dob: `${('0' + inputValues.dob.day).slice(-2)}/${('0' + inputValues.dob.month).slice(-2)}/${inputValues.dob.year}`,
          postcode: inputValues.postcode,
          emailAddress: inputValues.emailAddress,
          password: inputValues.password,
          tandc: tandC,
          optIn: !optOut,
          redirectUrl: checkoutCompletePath && checkoutCompletePath !== '/' ? `/menu/${restaurantId}` : null,
          source: isDelivery ? 'Delivery' : 'Collection',
          CaptchaToken: captchaToken
        });
        if (registrationResponse) {
          if (registrationResponse.errors?.length > 0 && captchaEnabled) {
            resetCaptcha();
          }
          if (registrationResponse.errors) {
            if (registrationResponse.errors[0].description === 'Customer email already exists') {
              setInputErrors({ ...inputErrors, masterError: ' ' });
            } else {
              setInputErrors({ ...inputErrors, masterError: registrationResponse.errors[0].description });
            }
            masterErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          } else {
            gtmEvent(isDelivery ? 'delivery_join_club' : 'Collection_join_club', { channel: inApp ? 'App' : 'Web' });
            freshRelevance('pageChange', null, { 'sendBeacon': true });
            if (!optOut) {
              freshRelevance('pageChange', null, { 'sendBeacon': true });
            }
            onContinue({ ...inputValues, dob, optIn: !optOut, tandC, ...registrationResponse.customer });
          }
        }
      }
    }
    setProcessing(false);
  };

  window.enableLoginButtonForTest = () => {
    setCaptchaToken('MetfSW3YY436A1UFDjmV');
    return 'OK!';
  };

  return (
    <Layout
      title={'Your contact details'}
      isFluid={true}
      backgroundColour={inApp ? 'malbec-100' : 'transparent'}
      titleColour={inApp ? 'rose-100' : 'black'}
      sectionSize={'is-padless-top is-small'}
      isBasket
      isSmallerTitle
      customStyles='is-basket'
      backButton={() => history.goBack()}>
      <div className="column is-12">
        <div className={`signup-content-wrapper ${inApp ? 'in-app' : ''}`}>
          <div className="column">
            <div className="content">
              <div className="block">
                <div className="columns is-flex-wrap-wrap">
                  <div ref={masterErrorRef}>
                    {inputErrors.masterError && (
                      <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginBottom: '0.75rem' }}>

                        <img id='masterError' src={errorIcon} alt="error" />

                        <div>
                          {inputErrors.masterError === ' ' ? (
                            <div className='error-message' style={{ paddingTop: 7 }}>
                              {features.loyalty ?
                                <>It looks like you already have an account. Please <span style={{ fontFamily: 'Jost SemiBold', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => historyMW.replace('/checkout/login', isDelivery, history, { emailAddress: inputValues.emailAddress })} className="link">Log in</span> with the correct email/password combination first to join PE club.</>
                                :
                                <>It looks like you already have an account. Please <span style={{ fontFamily: 'Jost SemiBold', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => historyMW.replace('/checkout/login', isDelivery, history, { emailAddress: inputValues.emailAddress })} className="link">Log in</span> with the correct email/password combination first.</>
                              }

                            </div>
                          ) : (
                            <div className='error-message'>
                              {inputErrors.masterError}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="column is-12 form-field-wrapper" ref={firstNameRef}>
                    <InputField
                      title="First Name"
                      name='firstName'
                      value={inputValues.firstName}
                      valueCallback={handleChange}
                      error={inputErrors.firstName}
                      errorCallback={handleError}
                      valueType="text"
                      required={true}
                      id='firstNameInput'
                      placeHolder='Your first name'
                    />
                  </div>
                  <div className="column is-12 form-field-wrapper" ref={lastNameRef}>
                    <InputField
                      title="Last Name"
                      name='lastName'
                      value={inputValues.lastName}
                      valueCallback={handleChange}
                      error={inputErrors.lastName}
                      errorCallback={handleError}
                      valueType="text"
                      required={true}
                      id='lastNameInput'
                      placeHolder='Your last name'
                    />
                  </div>
                  <div className="column is-12 form-field-wrapper" ref={emailAddressRef}>
                    <InputField
                      title="Email Address"
                      name='emailAddress'
                      value={inputValues.emailAddress}
                      valueCallback={handleChange}
                      error={inputErrors.emailAddress}
                      errorCallback={handleError}
                      valueType="email"
                      type="email"
                      required={true}
                      id='emailAddressInput'
                      placeHolder='Your email address'
                    />
                  </div>
                  <div className="column is-12 form-field-wrapper" ref={mobileNumberRef}>
                    <InputField
                      title="Mobile Number"
                      name='mobileNumber'
                      value={inputValues.mobileNumber}
                      valueCallback={handleChange}
                      error={inputErrors.mobileNumber}
                      errorCallback={handleError}
                      valueType="tel"
                      type="tel"
                      required={true}
                      maxLength="11"
                      id='mobileNumberInput'
                      placeHolder='Your mobile phone number'
                    />
                  </div>
                  <div className="column is-12 form-field-wrapper pb-0">
                    <div className="block" style={{ marginBottom: 4 }}>
                      <p className="label">Date of Birth *</p>
                    </div>
                    <div className="block">
                      <p className="label sub-label is-small  mt-5">
                        We&apos;ll help you celebrate with special birthday rewards.
                      </p>
                    </div>
                    <div className="block pb-5" ref={dobRef}>
                      <DobPicker
                        title="Dob"
                        name='dob'
                        value={inputValues.dob}
                        valueCallback={handleChange}
                        error={inputErrors.dob}
                        errorCallback={handleError}
                        id='dobPicker'
                      />
                    </div>
                  </div>
                  <div className="column is-12 form-field-wrapper" ref={postcodeRef}>
                    <InputField
                      title="Postcode"
                      name='postcode'
                      value={inputValues.postcode}
                      valueCallback={handleChange}
                      error={inputErrors.postcode}
                      errorCallback={handleError}
                      valueType="postcode"
                      required={true}
                      id='postcodeInput'
                      placeHolder='Your postcode'
                    />
                  </div>
                  {guest && features.loyalty && (
                    <div id='guestOptInButton' onClick={() => setIsRecieveOffers(!isRecieveOffers)} className="column is-12 form-field-wrapper">
                      <label className="switch is-flex is-justify-content-flex-start" style={{ alignItems: 'center' }}>
                        <i style={{ width: 60, marginTop: 10, marginLeft: 10, marginRight: -10 }}>
                          <img className="check" src={isRecieveOffers ? checkSelected : checkNotSelected} alt="checked" width='32px' height='32px' />
                        </i>
                        <div className="field-label">Join PizzaExpress Club</div>
                      </label>
                      <div className="block">
                        Simply create password to join and earn free Dough Balls
                        with this order.
                      </div>
                    </div>
                  )}
                  {(!guest || isRecieveOffers) && (
                    <div className="column is-12 form-field-wrapper" ref={passwordRef}>
                      <PasswordField
                        title="Password"
                        name="password"
                        value={inputValues.password}
                        valueCallback={handleChange}
                        error={inputErrors.password}
                        errorCallback={handleError}
                        valueType="text"
                        required={true}
                        id='passwordInput'
                        placeHolder='Create a strong password'
                      />
                      <PasswordChecker value={inputValues.password} />
                    </div>
                  )}

                  <div className={'column is-12 form-field-wrapper pb-0'}>
                    <GreenTickAndText
                      selected={tandC}
                      setSelected={updateTanC}
                      id='tandCButton'
                      error={inputErrors.tandC}
                      compRef={tandCRef}
                    >
                      I have read and agree to the
                      <a className="link" style={{ margin: 4 }} href={brand === 'PE' ? 'https://www.pizzaexpress.com/terms-and-conditions/online-ordering' : 'https://www.milano.ie/Terms-and-Conditions/Click-and-Collect'} target='_blank' rel='noopener noreferrer'>Terms & Conditions</a>
                      of this website and
                      <a className="link" style={{ margin: 4, marginRight: 1 }} href={brand === 'PE' ? 'https://www.pizzaexpress.com/privacy-policy' : 'https://www.milano.ie/privacy-policy'} target='_blank' rel='noopener noreferrer'>Privacy Policy</a>.
                    </GreenTickAndText>

                    {inputErrors.tandC && (
                      <div className="level is-mobile is-family-code">
                        <div className="level-item is-narrow is-shrinkable is-align-top">
                          <i className="icon" >
                            <img src={errorIcon} alt="error" />
                          </i>
                        </div>
                        <div className="level-item is-growable is-shrinkable is-justify-content-flex-start is-align-top">
                          <div className='error-message'>You must agree to the Terms and Conditions to be able to continue.</div>
                        </div>
                      </div>
                    )}
                    <GreenTickAndText
                      selected={optOut}
                      setSelected={setOptOut}
                      id='detailOutButton'
                    >
                      When you make your payment, {
                        brand === 'PE' ?
                          <span>PizzaExpress </span>
                          :
                          <span>Milano </span>
                      }will send you news and fantastic offers via email. Please tick the box if you do not want to receive this.
                    </GreenTickAndText>
                  </div>

                  <Captcha captchaRef={captchaRef} style={{ width: '100%' }}></Captcha>

                </div>
              </div>
            </div>
          </div>
        </div>

        {
          !isDelivery && !fromBanner &&
          <>
            {/* <SimpleHero title="collection time" /> */}
            <div className="signup-content-wrapper">
              <CollectionTime fullWidth />
            </div>
          </>
        }
        <div className="column p-0" style={{ textAlign: 'center' }}>
          {processing ? (
            <div className="btn btn-primary" style={{ width: '190px', height: '48px' }}>
              <img src={LoaderWhite} style={{ width: 24, height: 24 }} />
            </div>
          ) : (
            <button
              id='continueButton'
              onClick={continueToPayment}
              className="btn btn-primary"
              style={{ margin: 'auto' }}
              disabled={isSigningInGuest || isRegisteringUser || isGettingApplicationState || isFetchingApplicationState || (!captchaToken && captchaEnabled)}>
              Continue
            </button>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SignUp;