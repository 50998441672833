import React, { useEffect, useRef } from 'react';
import braintree from 'braintree-web';
import { freshRelevance, gtmEvent } from '../../../helpers/commonHelpers';
import { paymentTypes } from '../../../contants/paymentType';
import { postMessageWithAwait } from '../../../helpers/appHelpers';

const PayByPaypal = ({
  clientToken,
  grandTotal,
  generatePaymentBody,
  paymentSuccessful,
  submitPaymentFail,
  currentlySubmittingPayment,
  pay,
  inApp,
  setDisablePaymentBackButton
}) => {
  const generatePaymentBodyRef = useRef(null);
  generatePaymentBodyRef.current = generatePaymentBody;

  const handleClick = () => {
    gtmEvent('paypal_click', { channel: inApp ? 'App' : 'Web' });
  };

  useEffect(() => {
    const renderDropIn = async () => {
      const clientInstance = await braintree.client.create({ authorization: clientToken });
      let paypalInstance = await braintree.paypalCheckout.create({ client: clientInstance });

      paypalInstance = await paypalInstance.loadPayPalSDK({
        intent: 'capture',
        currency: 'GBP',
        commit: true,
        vault: false
      });
      window.paypal.Buttons({
        disabled: false,
        style: {
          color: 'silver',
          label: 'pay',
          layout: 'horizontal',
          shape: 'pill',
          height: 48
        },
        fundingSource: window.paypal.FUNDING.PAYPAL,
        ...!inApp ? {
          onClick: () => {
            handleClick();
          },
          createOrder: async () => {
            return paypalInstance.createPayment({
              flow: 'checkout', // Required
              amount: grandTotal, // Required
              currency: 'GBP', // Required
              intent: 'capture',
              enableShippingAddress: false,
              shippingAddressEditable: false,
              landingPageType: 'billing',
              locale: 'en-GB'
            });
          },
          onApprove: async (payload) => {
            const tokenizedPayment = await paypalInstance.tokenizePayment(payload);
            const response = await pay(generatePaymentBodyRef.current(tokenizedPayment, paymentTypes.PayPal));
            const data = response.data || response.error?.data;
            if (data?.status !== 'OK') {
              submitPaymentFail(data);
            } else {
              freshRelevance('pageChange', null, { 'sendBeacon': true });
              paymentSuccessful(data);
            }
            paypalInstance.teardown();
          },
          onError: (data) => {
            console.error('PayByPaypal error ', data);
          }
        } : {
          onClick: async (e) => {
            const payData = {
              clientToken,
              orderTotal: grandTotal,
              applePay: false,
              googlePay: false,
              vaultManager: false,
              payPal: true,
              cardDisabled: true,
              darkTheme: true,
            };
            try {
              handleClick();
              const result = await postMessageWithAwait(
                { type: 'PAYPAL_PAY_START', payload: payData },
                ['PAYPAL_PAY_TOKEN', 'PAYMENT_FAILURE']
              );
              if (!result || result.type === 'PAYMENT_FAILURE') throw new Error('Payment Failure');
              setDisablePaymentBackButton(true);
              const response = await pay(generatePaymentBodyRef.current(result.payload, paymentTypes.PayPal));
              const data = response.data || response.error?.data;
              if (data?.status !== 'OK') {
                submitPaymentFail(data);
              } else {
                freshRelevance('pageChange', null, { 'sendBeacon': true });
                paymentSuccessful(data);
              }
            } catch (err) {
              if (!err.message.includes('User closed the Payment Request UI.')) {
                console.error(err);
                submitPaymentFail();
              }
              setDisablePaymentBackButton(false);
            }
            return;
          }
        }
      }).render('#paypal-button');
    };

    renderDropIn();
  }, []);

  return (
    <div style={currentlySubmittingPayment ? { pointerEvents: 'none' } : {}} >
      <div id="paypal-button"></div>
    </div>
  );
};

export default PayByPaypal;