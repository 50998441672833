import React, { useRef } from 'react';
import icon from '../img/icons/question.svg';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const InfoPopOver = ({ text, parentRef = { current: {} }, invert = false}) => {
  const ref = useRef({ current: {} });

  const offset = [
    parentRef.current?.offsetLeft + 0.5 * parentRef.current?.clientWidth - ref.current?.offsetLeft - 0.5 * ref.current?.offsetWidth,
    ref.current?.clientHeight / 2
  ];

  return (
    <Tippy
      className="delivery-details-tippy"
      hideOnClick={false}
      offset={offset}
      content={<p>{text}</p>}>
      <img ref={ref} id='upperAndLowerIndicator' className={invert ? 'invert' : null} style={{marginBottom: '-2px'}} src={icon} alt="info" />
    </Tippy>
  );
};

export default InfoPopOver;