import React from 'react';
import { useSelector } from 'react-redux';
import { useBreakPoint } from '../../hooks/useBreakPoint';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { gtmEvent } from '../../helpers/commonHelpers';

const MeetMinimumOrder = ({ testId, navigateTo, title, message, restaurantId, warning }) => {
  const { width } = useWindowDimensions();
  const isSmallScreen = useBreakPoint(width, 530);
  const { isDelivery } = useSelector(state => state.basket);
  const { inApp } = useSelector(state => state.session);

  const handleContinue = () => {
    gtmEvent(isDelivery ? 'Delivery_Add_More_Items' : 'Collection_Add_More_Items', { channel: inApp ? 'App' : 'Web' });
    navigateTo(`/menu/${restaurantId}`);
  };

  return (
    <div
      className="filter-modal pt-0 pb-5"
      data-testid={testId}
      style={{ textAlign: inApp ? 'center' : '' }}
    >
      {inApp && <img src={warning.src} style={{ width: 120, marginTop: inApp ? 40 : '' }} />}
      <div className='content w100'>
        <h2
          style={{
            marginTop: '2rem',
            marginBottom: 0,
            lineHeight: '90%',
            textTransform: inApp ? 'uppercase' : '',
            fontSize: inApp ? '2rem' : ''
          }} className="title is-4">{title}</h2>
        <p style={{ margin: `1.5rem ${isSmallScreen ? '0.4rem' : '0.7rem'}`, fontSize: '1rem' }}>{message}.</p>

        <div className="is-flex is-flex-direction-column is-align-items-center" style={{ gap: '0.5rem' }}>
          <button id='confirm' style={{ fontSize: '1rem' }} className='btn btn-primary' onClick={handleContinue}>
            Add more items
          </button>
        </div>
      </div>
    </div>
  );
};

export default MeetMinimumOrder;
