import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import UpsellModalCard from '../UpsellModalCard';
import Cross from '../shared/Cross';
import { getUniqueItems, checkIfAddable, checkIfCustomisable, applyDefaultSubProducts } from '../../helpers/basketHelpers';
import { freshRelevance, freshTrackClick, gtmEvent } from '../../helpers/commonHelpers';
import { useAddToBasketMutation } from '../../services/basket.api';
import hash from 'object-hash';
const UpsellModal = ({ onClose, restaurantID, screenWidth, navigateTo, upsell, isSuccess, outOfStockItems, refetch, refreshBasket }) => {
  const { restaurant, inApp } = useSelector(state => state.session);
  const [upsellItems, setUpsellItems] = useState([]);
  const [currentDips, setCurrentDips] = useState([]);
  const [addToBasket] = useAddToBasketMutation();
  const { enableFreshRecommendation } = useSelector(state => state.session.features);
  const { isDelivery } = useSelector(state => state.basket);

  const handleContinue = async (valuesChanged = false, dipsPresent = true) => {
    gtmEvent(isDelivery ? 'Delivery_Continue_From_Dip_Modal' : 'Collection_Continue_From_Dip_Modal', { dipsPresent, channel: inApp ? 'App' : 'Web' });
    if (valuesChanged) {
      const body = currentDips.map((item) => {
        const itemWithDefaults = applyDefaultSubProducts(item);
        if (checkIfCustomisable(item.subProducts)) {
          const { quantity, ...itemToHash } = itemWithDefaults;
          // below object is needed only to create hash
          const customID = hash(itemToHash);
          return { ...itemWithDefaults, restaurantId: restaurant.id, customID, quantity };
        }
        return { ...itemWithDefaults, restaurantId: restaurant.id };
      });

      const response = await addToBasket(body);

      if (response?.data?.status === 'OK') {
        refreshBasket();
        refetch({ id: restaurantID, type: 'Upsell' });
        const url = `order.pizzaexpress.com/${isDelivery ? 'delivery' : 'collection'}`;
        currentDips.forEach((dip) => {
          if (enableFreshRecommendation && dip.meta && dip.quantity > 0 && Object.keys(dip.meta).length > 0) {
            freshTrackClick(dip.meta, url, dip.objectId);
          }
        });
        freshRelevance('pageChange', null, { 'sendBeacon': true });
        navigateTo('/basket');
      }
    } else {
      freshRelevance('pageChange', null, { 'sendBeacon': true });
      onClose();
      navigateTo('/basket');
    }
  };

  const handleQuantityChange = (item, isAdding) => {

    setCurrentDips(prev => {
      const exists = prev?.find((dip) => dip.id === item.id);

      if (exists) {
        return ([...prev].map((dip) => {
          if (dip.id === item.id) {
            if (isAdding) {
              return ({ ...dip, quantity: dip.quantity + 1 });
            } else {
              return ({ ...dip, quantity: Math.max(dip.quantity - 1, 0) });
            }
          }
          return dip;
        }).filter(i => i.quantity > 0));

      } else if (!exists && isAdding) {
        return ([...prev, { ...item, quantity: 1 }]);
      } else if (!exists && !isAdding) {
        return prev;
      }
    });
  };

  const checkQuantityChanged = () => {
    const changed = currentDips.some(dip => dip?.quantity > 0);

    return changed;
  };

  useEffect(() => {
    if (isSuccess && upsell) {
      if (upsell.dips.length === 0) handleContinue(false, false);
      setUpsellItems(getUniqueItems(upsell.dips));
    }
  }, [upsell]);

  return (
    <div
      className='upsell-modal block is-flex-direction-column'
      style={inApp ? { textAlign: 'center' } : {}}
    >
      <div className='header-wrapper'>
        <h1 className={`title mb-0-mobile mb-0-tablet mb-0-desktop ${!inApp && 'has-text-left'}`}
          style={inApp ? { padding: '2rem 0rem' } : {}}
        >
          WHY NOT TRY?</h1>
        {!inApp && <Cross onClick={onClose} />}
      </div>
      <div className='block'>
        <div className='columns is-mobile' style={{
          padding: screenWidth < 769
            ? inApp
              ? '1rem 0.5rem'
              : 0
            : '',
          justifyContent: 'center',
          gap: inApp && '1rem',
        }}>
          {
            upsellItems
              .map(item => {
                const outOfStock = outOfStockItems.includes(item.id);
                return { ...item, outOfStock };
              })
              .filter(item => !item?.outOfStock && checkIfAddable(item?.subProducts)).slice(0, 2).map((item, i) => (
                <div
                  className={`column ${screenWidth < 769 && !inApp ? 'is-6' : upsellItems.length > 1 ? 'is-6' : 'is-12'}`}
                  key={i}
                  style={{
                    backgroundColor: inApp ? '#fff' : '',
                    width: inApp ? '8rem' : '',
                    display: 'flex',
                    alignItems: 'stretch',
                    alignContent: 'stretch',
                    flexDirection: 'column',
                    flexGrow: '100',
                    padding: screenWidth < 769
                      ? inApp
                        ? '1rem 1rem 1.5rem'
                        : 0
                      : '',
                    borderRadius: inApp ? '0.5rem' : '',
                  }}
                >
                  <UpsellModalCard screenWidth={screenWidth}
                    item={item}
                    modalItem
                    quantity={(currentDips?.find(dip => dip.id === item.id))?.quantity ?? 0}
                    onChange={handleQuantityChange}
                    id={'upsell-' + i}
                    inApp={inApp}
                  />
                </div>
              ))
          }
        </div>
      </div>
      <div className="upsell-modal-cont-button-wrapper"
      style={inApp ?{paddingTop: '1rem', marginTop: '1rem' }:{}}
      >
        <button id='confirm' className='btn btn-primary' onClick={() => handleContinue(checkQuantityChanged())}>
          {`${checkQuantityChanged() ? 'Add' : 'Continue'} to basket`}
        </button>
      </div>
    </div >
  );
};

export default UpsellModal;
