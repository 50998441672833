import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  copyMenuItemWithoutSubItems,
  copyMenuItemWithoutParent,
  addToppingToQuestion,
  addMenuItemToQuestion,
  removeToppingToQuestion,
  calcSubTotal,
  findQuestionOptions
} from '../../../helpers/basketHelpers';
import CustomiseCard from './CustomiseCard';

const CustomiseSection = ({
  item,
  NewComponent,
  result,
  setCustomisedObj,
  invalidSections,
  setInvalidSections,
  showErrors,
  resetItem,
  outOfStockItems,
  duoRomanaId,
  isDuoRomana
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [nextItems, setNextItems] = useState([]);
  const [error, setError] = useState(false);
  const { filters } = useSelector(state => state.menu);
  useEffect(() => {
    const invalid = invalidSections.find(el => el === item.id);
    if (invalid) {
      setError(true);
    } else {
      setError(false);
    }

  }, [invalidSections]);

  useEffect(() => {
    const newOptions = findQuestionOptions(result, item.name);
    setSelectedOptions(newOptions);
    if(isDuoRomana && item.parent.id !== duoRomanaId) return;

    const selectedOptions = item.subProducts.filter(el => newOptions.find(option => el.name === option.name));
    const newNextItems = selectedOptions.reduce((acc, cur) => acc.concat(cur.subProducts), []);
    setNextItems(newNextItems);
  }, [result]);

  useEffect(() => {
    if (item.min === 1 && item.max <= item.min && selectedOptions.length === 0) {
      setInvalidSections(sections => [...sections, item.id]);
    } else {
      setInvalidSections(sections => sections.filter(el => el !== item.id));
    }
  }, [selectedOptions]);

  const findAndReplace = (resultObj, option, replaceMethod) => {
    const newValue = { ...resultObj };

    if (newValue.id === option.parent?.parent?.id) {
      let question = newValue.subProducts.find(p => p.id === option.parent.id);
      if (!question) {
        question = { ...option.parent, subProducts: [], calcSubTotal, quantity: 1 };
        newValue.subProducts.push(copyMenuItemWithoutParent(question));
      }
      replaceMethod(item, question, option);
      setSelectedOptions(question.subProducts);
      setNextItems(option.subProducts.map(p => ({ ...p, parent: copyMenuItemWithoutSubItems(question) })));
    } else if (newValue.id === option.parent.id) {
      replaceMethod(item, newValue, option);
      setSelectedOptions(newValue.subProducts.map(p => p.id));
      setNextItems(option.subProducts);
    } else {
      newValue.subProducts.map(product => findAndReplace(product, option, replaceMethod));
    }
    return newValue;
  };

  const handleSelectCard = (option) => {
    setCustomisedObj(findAndReplace(result, option, addMenuItemToQuestion));
  };

  const handleSelectTopping = (option) => {
    setCustomisedObj(findAndReplace(result, option, addToppingToQuestion));
  };

  const handleUnselectTopping = (option) => {
    setCustomisedObj(findAndReplace(result, option, removeToppingToQuestion));
  };
  
  return (
    <>
      {
        item &&
        <>
          {
            item.subProducts.length !== 1 && item.subProducts[0]?.name !== 'Standard' ? (
              <div className='block customise-section' data-section-error-id={error ? item.id : null} style={{ marginTop: '0.5rem' }}>
                <div style={{display: 'flex', flexDirection: 'column', gap: 8, marginBottom: 24}}>
                  <div className='is-flex is-justify-content-space-between is-align-items-center'>
                    <h2 className="title customise-section-header" style={{lineHeight: '120%'}}>{item.name}</h2>
                    {
                      item.name?.toLowerCase()?.includes('toppings') &&
                      <a className='link dm-sans' style={{ fontSize: 18 }} onClick={resetItem}>Reset Pizza</a>
                    }
                  </div>
                  {
                    error && showErrors ?
                      <p className='error' style={{ color: 'crimson' }}>{'Please select an option'}</p>
                      :
                      null
                  }
                  {
                    item.min === 1 && item.max <= item.min ?
                      <p style={{ fontSize: 16 }}>Select one of the options below.</p>
                      :
                      <p style={{ fontSize: 16 }}>Each additional topping is chargeable.</p>
                  }
                </div>
                {item.subProducts?.filter(option => !option.isIncluded)
                  .filter(el => {
                    if (!filters.length) return true;
                    if (selectedOptions.find(o => o.name === el.name)) return true; // If the subproduct is already selected, don't filter it out
                    if (!el.dietPreferences.length) return true; // If the subproduct has no diet preferences, assume it is a "product choice item" (e.g. "Choose your base" & "Why not add dough sticks")
                    if (filters.every(pref => el.dietPreferences.includes(pref))) return true;
                  })
                  .filter(option => {
                    const outOfStock = outOfStockItems.includes(option.id);
                    return !outOfStock;
                  })
                  .map((option, i) => {
                    const selected = selectedOptions.find(o => o.name === option.name);
                    return (
                      <React.Fragment key={i}>
                        <CustomiseCard
                          index={i}
                          name={option.name}
                          cost={option.cost}
                          parent={item}
                          option={option}
                          selected={selected}
                          cardSelect={() => handleSelectCard(option)}
                          handleIncrease={() => handleSelectTopping(option)}
                          handleDecrease={() => handleUnselectTopping(option)}
                          reachedMax={item.max <= selectedOptions.reduce((acc, option) => option.quantity + acc, 0)}
                        />
                      </React.Fragment>
                    );
                  })
                }
              </div>
            ) : null
          }
          {
            nextItems.map((childQuestion, idx) =>
              <NewComponent
                key={idx}
                item={childQuestion}
                NewComponent={NewComponent}
                result={result}
                setCustomisedObj={setCustomisedObj}
                invalidSections={invalidSections}
                setInvalidSections={setInvalidSections}
                showErrors={showErrors}
                resetItem={resetItem}
                outOfStockItems={outOfStockItems}
                isDuoRomana={isDuoRomana}
                duoRomanaId={duoRomanaId}
              />
            )
          }
        </>
      }
    </>
  );
};

export default CustomiseSection;
