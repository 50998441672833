export const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const serverDateFormat = 'yyyy-MM-dd\'T\'HH:mm:ss';
export const serverTimeFormat = 'HH:mm:ss';
import TagManager from 'react-gtm-module';
import { config } from '../config.js';
const brand = config.BRAND;

export const gtmEvent = (event, params = {}) => {
  console.log('GTMEVENT: ', event);
  TagManager.dataLayer({
    dataLayer: {
      event,
      ...params
    }
  });
};

export const throttle = (fn, delay) => {
  let timer;
  return (...args) => {
    timer && clearTimeout(timer);
    timer = setTimeout(() => fn(...args), delay);
  };
};

export const convertHoursToMinutes = (hhmmss) => {
  const strArr = hhmmss.split(':');
  const hours = parseInt(strArr[0]);
  const mins = parseInt(strArr[1]);
  return (hours * 60) + mins;
};

export const addTime = (hhmmss, currentTime = new Date()) => {
  const strArr = hhmmss.split(':');
  const hours = parseInt(strArr[0]);
  const mins = parseInt(strArr[1]);
  const newHours = currentTime.getHours() + hours;
  const newMins = currentTime.getMinutes() + mins;
  
  const additionalHours = Math.floor(newMins / 60) + newHours;
  let remainderMins = newMins % 60;
  if (remainderMins < 10) remainderMins = '0' + remainderMins;
  const remainingHours = `${additionalHours < 10 ? '0' : ''}${additionalHours}`;
  return `${remainingHours}:${additionalHours > 0 ? remainderMins : newMins}`;
};

export const convertDeliveryTimeToDisplayTime = time => {
  if (time[0] === '0' && time[1] === '0') return time.split(':')[1];
  return time[1] + ':' + time.split(':')[1];
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export const freshRelevance = (eventName, eventData, options) => {
  if (window.$TB) {
    window.$TB.hooks.fireEvent(eventName, eventData, options);
  }
};

export const freshTrackClick = (meta, url, productId) => {
  if(window.$TB?.trackClick) {
    window.$TB.trackClick(meta, url, productId);
  }
};

export const getDistanceText = (distance) => {
  if (!distance || !+distance) return '';

  if (brand == 'MI') {
    return (~~distance / 1000).toFixed(2) + ' km';
  }
  return (~~distance / 1609.34).toFixed(2) + ' mi';
};